.torre-controle {
  &.cpanel-manager {
    .summary {
      .summary-items {
        @include flexbox();

        .box {
          padding: 10px 20px;
        }

        > li {
          @extend .box;
          position: relative;
          @include flexbox();
          @include flex-align-items(center);

          &:before {
            content: "";
            width: 6px;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background: $color-gray;
          }

          + li {
            margin-left: $main-padding;
          }

          &:nth-child(2) {
            fa-icon {
              font-size: $fs-28;
            }
          }

          fa-icon {
            font-size: $fs-30;
            margin-right: $spacing-md;
          }

          .currency {
            font-size: $fs-24;
            font-style: normal;
            font-weight: 500;
            margin-right: $spacing-md;
          }

          p {
            font-size: $fs-24;
            font-weight: 500;
          }

          small {
            font-size: $fs-14;
            font-weight: 400;
            display: block;
          }

          .chart-area {
            width: 50px;
            text-align: center;
            margin: 0 0 -4px $spacing-md;

            small {
              font-weight: 500;
              padding: 0 10px 5px 0;
            }
          }
        }

        .sla {
          @include flexbox();
          @include flex-align-items(center);

          li {
            @include flexbox();
            @include flex-align-items(center);

            + li {
              padding-left: calc(#{$spacing-md + 5px});
              margin-left: $spacing-md;
              border-left: $border-divider;
            }
          }
        }

        /* alerts */
        .green {
          &:before {
            background: $color-blue;
          }

          fa-icon,
          .currency {
            color: $color-blue;
          }

          .chart-area {
            small {
              color: $color-blue;
            }
          }
        }

        /* /// */

        .yellow {
          &:before {
            background: $color-blue;
          }

          fa-icon,
          .currency {
            color: $color-blue;
          }

          .chart-area {
            small {
              color: $color-blue;
            }
          }
        }

        /* /// */

        .red {
          &:before {
            background: $color-blue;
          }

          fa-icon,
          .currency {
            color: $color-blue;
          }

          .chart-area {
            small {
              color: $color-blue;
            }
          }
        }
      }
    }

    /* ////////////////////////////////////////////////////////////////// */

    .dashboard {
      @include flexbox();

      > section {
        + section {
          margin-left: $spacing-md;
        }
      }

      /* ////////////////////////////////////////////////////////////////// */

      .separacao {
        width: 35%;

        .separacao-group {

          .scrollbar {
            height: calc(100vh - 450px);
            @include flexbox();

            > div {
              width: calc(50% - #{$spacing-sm / 2});

              + div {
                padding-left: $spacing-sm;
                margin-left: $spacing-sm;
              }
            }
          }

          .separacao-lentas {
            .table {
              .table-header,
              .table-content {
                li {
                  &:first-child {
                    span {
                      background: transparentize($color-red, .6);
                    }
                  }
                }
              }
            }
          }

          .separacao-rapidas {
            .table {
              .table-header,
              .table-content {
                li {
                  &:first-child {
                    span {
                      background: transparentize($color-green, .6);
                    }
                  }
                }
              }
            }
          }

          .table {
            .table-header,
            .table-content {
              li {
                width: calc(75% - #{$table-padding * 2 * 2} - 5px);

                &:first-child {
                  width: 55px;

                  span {
                    width: 55px;
                    display: block;
                    padding: $spacing-sm;
                    background: transparentize($color-blue, .6);
                    margin: 0 - $spacing-sm;
                  }
                }

                .item-name {
                  padding: 0 $spacing-sm;
                }
              }
            }
          }

          .legend {
            @include flexbox();
            @include flex-justify-content(center);
            margin-bottom: $spacing-md;

            li {
              font-size: $fs-12;
              padding-left: 45px;
              position: relative;

              &:before {
                content: "";
                width: 40px;
                height: 15px;
                position: absolute;
                left: 0;
              }

              &:first-child {
                &:before {
                  background: transparentize($color-red, .6);
                }
              }

              &:last-child {
                &:before {
                  background: transparentize($color-green, .6);
                }
              }

              + li {
                margin-left: calc(#{$spacing-sm + $table-spacing});
              }
            }
          }

        }
      }

      /* ////////////////////////////////////////////////////////////////// */

      .chart-sla-delivery-venda {
        width: 37%;

        .header-actions {
          li {
            + li {
              margin-left: $spacing-sm;
              border-left: $border-divider;
            }
          }
        }

        /* /// */

        .filter-settings {
          @include flexbox();
          @include flex-align-items(flex-end);

          li {
            .label {
              font-size: $fs-14;
              margin-bottom: 4px;
            }

            .mat-radio-label {
              padding: $spacing-sm;
              background: white;
              border: $border-divider;
              @include border-radius(4px);
              @include transition (all 0.2s ease-in-out);
            }

            .mat-radio-checked {
              .mat-radio-label {
                background: transparentize($color-green-lt, .8);
                @include transition (all 0.2s ease-in-out);
              }
            }

            /* /// */

            .mat-radio-group {
              @include flexbox();
              @include flex-align-items(center);
              border-right: none;
            }

            .mat-radio-button {
              + .mat-radio-button {
                margin-left: $spacing-sm;
              }

              &.mat-radio-checked {
                .table-content {
                  background: transparentize($color-green, .7);
                }
              }

              .mat-radio-label-content {
                padding: 0;
              }

              .mat-radio-container {
                display: none;
              }
            }

            /* /// */

            .fm-group {
              width: 150px;
            }
          }

          /* /////////////// */

          .mat-radio-button,
          .mat-radio-label-content {
            width: 100%;
          }
        }

        /* /// */

        .sla-delivery-venda {
          height: calc(100% - 100px);
          @include flexbox();
          @include flex-align-items(center);
        }

        .chart-bar {
          width: 100%;
          height: calc(100% - 60px);

          canvas {
            max-height: calc(50vh - 105px);
          }
        }
      }

      /* ////////////////////////////////////// */

      .chart-sla-group {
        width: 28%;
        @include flexbox();
        @include flex-direction(column);
        @include flex-justify-content(center);

        > div {
          + div {
            margin-top: $spacing-md;
          }
        }

        .chart-donut {
          .legend {
            li {
              &:first-child {
                font-weight: 800;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }


    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-xlg) {

      .dashboard {

        .separacao {
          .separacao-group {
            .table {
              .table-header,
              .table-content {
                li {
                  width: calc(75% - 13px);
                }
              }
            }
          }
        }

        /* ////////////////////////////////////// */

        .chart-sla-delivery-venda {
          width: 40%;
        }

        /* ////////////////////////////////////// */

        .chart-sla-group {
          width: 30%;

          .chart-donut {
            .chart {
              width: calc(50vh - 282px);
              height: calc(50vh - 282px);
              min-width: auto;
              min-height: auto;
            }

            .legend {
              li {
                &:first-child {
                  font-size: $fs-16;
                }

                + li {
                  margin-top: $spacing-sm;
                }
              }
            }
          }
        }

      }

    }

  }
}

