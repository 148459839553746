.user-button {
  padding-left: 10px;
  position: relative;
  @include flexbox();

  .mat-button-wrapper {
    @include flexbox();
    @include flex-align-items(center);
  }

  > button {
    min-width: 70px;
    height: 100%;
    color: white;
    padding: 0;
    background: transparent;

    .avatar {
      width: 30px;
      height: 30px;
      position: relative;
      @include flexbox();
      img {
        @include border-radius(100rem);
      }

      .status {
        width: 10px;
        height: 10px;
        position: absolute;
        top: -2px;
        right: -2px;
        background: $color;
        border: 1px solid transparentize(white, .4);
        @include border-radius(100rem);

        &.online {
          background: $color-brand-three;
        }
      }
    }

    .name {
      font-size: $fs-14;
      margin: 0 $spacing-sm;
    }

    fa-icon {
      font-size: $fs-16;
      transform: rotate(0);
      transition: transform .25s cubic-bezier(.25,.8,.25,1);
    }

    &.open {
      background: rgba(0, 0, 0, .05);

      fa-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .dropdown {
    width: 100%;
    min-width: 160px;
    color: $color;
    padding: $main-padding 0;
    background: white;
    position: absolute;
    top: calc(#{$header-height - 10px});
    right: 0;
    z-index: -1;
    visibility: hidden;
    transition: transform .4s cubic-bezier(.25,.8,.25,1), visibility .4s cubic-bezier(.25,.8,.25,1);
    @include transform(translateY(-110%));

    &.open {
      visibility: visible;
      @include transform(translateY(0));
    }

    li {
      padding: $spacing-sm $main-padding;
      cursor: pointer;

      &:hover {
        background: $color-gray-xlt;
      }

      fa-icon {
        margin-right: $spacing-sm;
      }
    }
  }
}
