.painel-nfce {
  margin: $main-padding;
  @include flexbox();

  .dashboard {
    width: 50%;

    section {
      height: calc(50vh - #{$header-height} - #{$breadcrumb-height} - #{$main-padding});

      + section {
        margin-top: $main-padding;
      }
    }

    /* /// */

    .chart-donut {
      .chart {
        width: calc(50vh - 180px);
        height: calc(50vh - 180px);

        .total {
          p {
            + p {
              margin-bottom: -10px;
            }
          }
        }
      }

      .legend {
        max-width: 380px;
        @include columns(2);

        .item {
          cursor: pointer;
        }
      }
    }

    /* ////////////// */

    .chart-bar {
      canvas {
        max-height: calc(50vh - 180px);
      }
    }
  }

  /* ////////////////////////////////////////////////////////// */

  .branch-statement {
    width: calc(50% - #{$main-padding});
    margin-left: $main-padding;

    .scrollbar {
      height: calc(100vh - #{$header-height} - #{$breadcrumb-height} - 140px);
    }

    .table {
      .table-header,
      .table-content {
        li {
          width: calc(100% / 7);
          text-align: center;
        }
      }
    }
  }
}
