.rio-log {
  &.carga-produtos {

    .import-settings {
      @include flexbox();
    }

    .import-upload {
      width: 30%;
      @include flexbox();
      @include flex-direction(column);

      .file-uploader {
        height: 100%;
        margin-top: $main-padding;

        .file-uploader-wrapper{
          height: 100%;
        }
      }
    }

    /* ////////////////// */

    .file-content {
      width: calc(70% - #{$main-padding});
      margin-left: $main-padding;

      /* //////////////////////////////////////////////////////// */

      .table {
        .scrollbar {
          height: 245px;
        }

        .table-header,
        .table-content {
          > li {
            width: calc(45% / 4);

            &:nth-child(1) {
              width: calc(25% - #{$table-spacing * 7});
            }

            &:nth-child(6) {
              width: 20%;
            }

            &:nth-child(7) {
              width: 10%;
              font-size: $fs-14;
              @include flex-justify-content(center);
            }
          }
        }

        .actions {
          font-size: $fs-16;
          @include flexbox();

          a {
            padding: 5px 10px;

            &.preview {color: $color-blue; cursor: pointer;}
            &.delete {color: $color-red; cursor: pointer;}
          }
        }

      }
    }

    /* ////////////////// */

    footer {
      margin: 0 $main-padding $main-padding $main-padding;
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(space-between);

      .process-steps {
        @include flexbox();
        @include flex-align-items(center);

        li {
          @include opacity(.4);
          @include flexbox();
          @include flex-align-items(center);

          > div {
            @include flexbox();
            @include flex-align-items(center);
          }

          + li {
            padding-left: 100px;
            margin-left: $spacing-sm;
            position: relative;

            &:before {
              content: "";
              width: calc(100px - #{$spacing-sm});
              height: 1px;
              position: absolute;
              top: calc(50% + 1px);
              left: 0;
              background: $color-gray;
            }
          }

          fa-icon {
            font-size: $fs-24;
            margin-right: $spacing-sm;

            &.spinner {
              width: 23px;
              height: 23px;
              background: $color-blue;
              color: white;
              font-size: $fs-14;
              @include spinner-rotate(1.5s linear infinite);
              @include border-radius(100rem);
              @include flexbox();
              @include flex-align-items(center);
              @include flex-justify-content(center);
            }
          }

          &.active {
            color: $color-blue;
            @include opacity(1);

            &:before {
              background: $color-green;
            }
          }

          &.error {
            color: $color-red;
            @include opacity(1);

            &:before {
              background: $color-red;
            }
          }

          &.done {
            color: $color-green;
            @include opacity(1);

            &:before {
              background: $color-green;
            }
          }
        }
      }

      /* /// */

      .buttons {
        margin-top: 0;
      }
    }
  }
}

