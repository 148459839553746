.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: none;
}

.mat-horizontal-stepper-header-container {
  .mat-horizontal-stepper-header {
    height: 65px;
  }
}

.mat-stepper-horizontal {
  .mat-horizontal-content-container {
    padding: 5px;
  }
}


/*
mat-stepper {

  &.mat-stepper-horizontal {
    background: none;
  }

  .mat-step-icon,
  .mat-stepper-horizontal-line,
  .mat-step-optional {
    display: none;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: $main-padding;
    overflow: hidden;
    @include border-radius(2px);
    @include box-shadow(1px 1px 4px transparentize(black, .8));
  }

  .mat-horizontal-stepper-header {
    width: 100%;
    height: auto;
    padding: 0;
    margin-left: -30px;
    overflow: visible;
    position: relative;
    filter: drop-shadow(1px 1px 4px transparentize(black, .8));

    &:nth-child(1) {
      z-index: 4;

      .mat-step-label {
        background: #f5f5f5;
      }
    }

    &:nth-child(3) {
      z-index: 3;

      .mat-step-label {
        background: #f5f5f5;
      }
    }

    &:nth-child(5) {
      z-index: 2;

      .mat-step-label {
        background: #eeeeee;
      }
    }

    &:nth-child(7) {
      z-index: 1;

      .mat-step-label {
        background: #e8e8e8;
      }
    }

    &:last-child {
      .mat-step-label {
        clip-path: none;
      }
    }

    &:hover {
      background: none;
    }

    &.cdk-program-focused {
      background: none;
    }

    .mat-step-label {
      width: 100%;
      color: transparentize($color, .5);
      padding: calc(#{$main-padding / 2}) $main-padding;
      position: relative;
      clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0 100%);
      background: $background;
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(center);

      &.mat-step-label-active {
        color: white;
        background: linear-gradient(175deg, #3b8ed0 50%, #2d86c8 50%);
      }
    }
  }
   
  













  

  .mat-step-text-label {
    @include flexbox();
    @include flex-align-items(center);

    .icon {
      font-size: $fs-26;
      margin-right: $main-padding;
    }

    .text {
      font-size: $fs-12;
      font-weight: 700;
      line-height: 16px;

      span {
        font-weight: 400;
        display: block;
      }
    }
  }

  !* ////////////////////////////////////// *!

  .mat-horizontal-content-container {
    padding: 0;
    background: none;
    overflow: visible;
  }
}
*/
