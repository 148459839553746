.torre-controle {
  &.cpanel-dynamic {
    .actions{
      h2{
       color:#e02a31;
     }
    }

    .cpanel-dynamic-container {
      @include flexbox();
    }

    /* ////////////////////////////// */

    .chart-group {
      width: 30%;
      margin-right: $main-padding;

      section {
        height: calc(50vh - 150px);

        + section {
          margin-top: $main-padding;
          width: calc(50vh - 50px);
        }
      }

      .content {
        height: calc(100% - 60px);
        @include flexbox();
        @include flex-align-items(center);
      }

      .chart-donut {
        .chart {
          width: calc(50vh - 210px);
          height: calc(50vh - 210px);
          cursor: pointer;

          .total {
            font-size: $fs-18;
          }
        }

        .legend {
          li {
            cursor: pointer;
          }
        }
      }
    }

    /* ////////////////////////////// */

    .order-details {
      width: calc(90% - 0px);

      .scrollbar {
        height: calc(100vh - 435px);

        .table-list{
          user-select: text;
        }
      }

      .table {
        .table-filter {
          li {
            &:last-child {
              margin-left: auto;
            }
          }
        }

        .table-header,
        .table-content {
          li {
            width: calc(75% / 9);

            &:nth-child(5) {
              width: calc(25% - #{$table-padding * 2 * 10});
            }
          }
        }
      }
    }


    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-xlg) {
      .chart-group {
        .chart-donut {
          .chart {
            width: calc(50vh - 250px);
            height: calc(50vh - 250px);

            .total {
              font-size: $fs-26;
            }
          }
        }
      }
    }

  }
}


