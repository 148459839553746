/* font size */

/*$font: 'azo-sans-web', sans-serif !default;*/
$font: 'Roboto', sans-serif !default;
$font-awesome-pro: "Font Awesome 5 Pro" !default;

/* ///////////////////////////////////////////////////////////////////// */

$fs-10  : 0.625rem !default; /*10px*/
$fs-12  :  0.75rem !default; /*12px*/
$fs-14  : 0.875rem !default; /*14px*/
$fs-16  :     1rem !default; /*16px*/
$fs-18  : 1.125rem !default; /*18px*/
$fs-20  :  1.25rem !default; /*20px*/
$fs-22  : 1.375rem !default; /*22px*/
$fs-24  :   1.5rem !default; /*24px*/
$fs-26  : 1.625rem !default; /*24px*/
$fs-28  :  1.75rem !default; /*30px*/
$fs-30  : 1.875rem !default; /*30px*/
$fs-32  :     2rem !default; /*32px*/
$fs-34  : 2.125rem !default; /*34px*/
$fs-36  :  2.25rem !default; /*36px*/
$fs-38  : 2.375rem !default; /*38px*/
$fs-40  :   2.5rem !default; /*40px*/
$fs-42  : 2.625rem !default; /*42px*/
$fs-44  :  2.75rem !default; /*44px*/
$fs-46  : 2.875rem !default; /*46px*/
$fs-48  :     3rem !default; /*48px*/
$fs-50  : 3.125rem !default; /*60px*/
$fs-60  :  3.75rem !default; /*60px*/
$fs-70  : 4.375rem !default; /*70px*/
$fs-80  :     5rem !default; /*80px*/
$fs-100 :  6.25rem !default; /*100px*/
$fs-140 :  8.75rem !default; /*140px*/

/* ///////////////////////////////////////////////////////////////////// */

$body-fs: (
  null   : $fs-16,
  medium : $fs-16,
  large  : $fs-16,
);

$h1-fs: (
  null   : $fs-18,
  medium : $fs-20
);

$h2-fs: (
  null   : $fs-16,
  medium : $fs-16
);

/* ///////////////////////////////////////////////////////////////////// */

.mat-form-field,
.mat-stepper-vertical,
.mat-stepper-horizontal,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: $font !important;
}
