.budget-contract {
  &.contract-report {
    .contract-report-list {

      .scrollbar {
        height: calc(100vh - 435px);

        .table-list{
          user-select: text;
        }
      }

      .table {
        .table-header,
        .table-content {
          li {
            width: calc(60% / 5);

            &:nth-child(1) {
              width: 5%;
            }

            &:nth-child(3) {
              width: 5%;
            }

            &:nth-child(2) {
              width: calc(30% - #{$table-padding * 2 * 6});
            }
          }
        }
      }
    }
  }
}
