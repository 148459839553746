.vtex {
  &.tratamento-pedidos {

    .content {
      height: calc(100vh - 180px);
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(center);
    }

    ul {
      width: 100%;
      max-width: 1240px;
      @include flexbox();

      li {
        @extend .box;
        width: calc(100% / 3 - #{$spacing-md * 2});
        text-align: center;
        margin-left: $spacing-md;
        padding: $spacing-lg;
        @include flexbox();
        @include flex-align-items(center);
        @include flex-justify-content(space-between);
        @include flex-direction(column);

        &:nth-child(1) {
          color: $color-orange;
          margin-left: 0;
        }

        &:nth-child(2) {
          color: $color-yellow;
        }

        &:nth-child(3) {
          color: $color-red;
        }
      }
    }

    fa-icon {
      font-size: $fs-80;

      + p {
        font-size: $fs-22;
        font-weight: 600;
        margin-top: $spacing-sm;
      }
    }

    .fm-group {
      width: 100%;
      margin: $spacing-md 0;
    }

    .buttons {
      @include flex-justify-content(center);
    }


    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-xlg) {

      ul {
        li {
          min-height: 400px;
        }
      }
    }

  }

}
