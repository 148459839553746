.dialog {
  &.import-log {
    width: 800px;

    .scrollbar {
      height: calc(100vh - 300px);
    }

  }
}
