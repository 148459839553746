.dialog {
  &.fechamento-caixa-valores-convenio {
    width: 1100px;

    .data-content {
      &.table {
        .scrollbar {
          height: 180px;
        }

        .table-header,
        .table-content {
          li {
            width: calc(40% / 5);

            &:nth-child(5),
            &:nth-child(6) {
              width: 20%;
            }

            &:nth-child(7) {
              width: calc(20% - #{$table-spacing * 8});
            }
          }
        }
      }
    }

  }
}
