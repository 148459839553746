.dialog {
  &.rio-log-lancamento {
    width: 1000px;

    .positive {color: $color-green;}
    .negative {color: $color-red;}
    .alert {color: $color-yellow;}
    .progress {color: $color-blue;}

    a {
      cursor: pointer;

      &.preview {color: $color-blue;}
    }

    /* //////////////////////////////////////////////////////// */

    .table {
      .table-filter {
        .filter-text {
          max-width: 70px;
        }
      }

      .scrollbar {
        height: 300px;
      }

      .table-header,
      .table-content {
        li {
          width: calc(53% / 6);

          &:nth-child(1) {
            width: 2%;
          }

          &:nth-child(3) {
            width: calc(38% - #{$table-spacing * 8});
          }

          &:nth-child(8) {
            width: 7%;
            @include flex-justify-content(flex-end);
          }

          /* /// */

          .mat-input-element,
          .mat-select-value {
            font-size: $fs-12;
          }

          mat-form-field {
            + a {
              font-size: $fs-14;
              margin-left: 10px;

              &.edit {
                color: $color-orange;
              }
            }
          }

        }
      }

      /* /// */

      .actions {
        font-size: $fs-16;
        @include flexbox();

        a {
          padding: 5px 10px;
        }
      }

    }
  }
}
