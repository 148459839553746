.dialog-desbloqueio-usuario-autorizacao {
  width: 840px;

  pdf-viewer {
    height: calc(100vh - 160px);
    user-select: all;
  }

  .ng2-pdf-viewer-container {
    @extend .scrollbar;
    padding-top: 3px;

    .page {
      @include box-shadow(1px 2px 5px transparentize(black, .8))
    }
  }

}
