.dialog {
  &.fechamento-caixa-modalidade-multiplas {
    width: 700px;

    section {
      + section {
        margin-top: $main-padding;
      }
    }

    .mat-input-element,
    .mat-select-value {
      font-size: $fs-12;
    }

    .data-content {
      &.table {
        .scrollbar {
          height: 145px;
        }

        .table-header,
        .table-content {
          li {
            width: calc(100% / 6);

            mat-form-field {
              width: 100%;
            }

            &:nth-child(2) {
              width: 40%;
            }

            &:nth-child(6) {
              width: 5%;
            }
          }
        }
      }
    }

    /* //////////////////////////////////// */



  }
}
