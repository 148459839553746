.budget-contract {
  &.contract-search {
    width: 600px;
    margin: -206px 0 0 -300px;

    .table {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 90px;
          }
          &:nth-child(2) {
            width: calc(70% - 90px - #{$table-padding * 2 * 2 * 2});
          }
          &:nth-child(3) {
            width: calc(30% - #{$table-padding * 2 * 2 * 2});
          }
        }
      }

      .table-header {
        width: calc(100%);
      }

      .table-list {
        a {
          padding: $table-padding 0;
          margin: -$table-padding 0;
          cursor: pointer;
        }
      }

      .scrollbar {
        height: 250px;
      }
    }
  }
}
