.dialog {
  &.rio-log-pesquisa-produto {
    width: 1000px;

    /* //////////////////////////////////////////////////////// */

    .table {
      .scrollbar {
        height: 300px;
      }

      .table-header,
      .table-content {
        li {
          width: calc(52% / 4);

          &:nth-child(2) {
            width: calc(40% - #{$table-spacing * 5});
          }

          &:nth-child(5) {
            width: calc(8% - #{$table-spacing * 5});
          }

          .preview {
            color: $color-blue;
            font-weight: bolder;
            cursor: pointer;
            font-size: large;
          }
        }
      }

    }
  }
}
