.dialog {
  &.fechamento-caixa-financeiro-recusado {
    width: 400px;

    p {
      font-weight: 700;

      + p {
        font-weight: 400;
        margin: 5px 0 $spacing-md 0;
      }
    }

  }
}
