.toolbar-button {
  position: relative;
  @include flexbox();
  margin-right: 10px;

  .new {
    position: relative;

    fa-icon {
      font-size: $fs-24;
    }

    .small {
        width: 17px;
        height: 17px;
        color: white;
        background: $color-red;
        position: absolute;
        top: 0;
        right: -5px;
        @include border-radius(100rem);
        @include flexbox();
        @include flex-align-items(center);
        @include flex-justify-content(center);
      }



  }

  .dropdown {
    min-width: 350px;
    color: $color;
    position: absolute;
    top: calc(#{$header-height - 12px});
    right: 0;
    z-index: -1;
    visibility: hidden;
    transform-origin: top right;
    transition: transform .4s cubic-bezier(.25,.8,.25,1), visibility .4s cubic-bezier(.25,.8,.25,1);
    @include transform(translateY(-110%) scale(0));

    &.open {
      visibility: visible;
      @include transform(translateY(0) scale(1));
    }

    header {
      h2 {
        font-weight: 400;
        margin-top: 5px;
      }
    }

    .mat-icon-button {
      color: $color;

    }


    .notifications {
      .scrollbar {
        height: 300px;
        overflow-x: hidden!important;
      }

      li {
        color: $color;
        @include flexbox();
        @include flex-align-items(center);
        @include flex-justify-content(space-between);

        + li {
          padding-top: $spacing-sm;
          margin-top: $spacing-sm;
          border-top: $border-divider;
        }

        .content {
          @include flexbox();
          @include flex-align-items(center);
          @include transition (all 0.1s ease-in-out);
          cursor: pointer;

          fa-icon {
            margin-right: $spacing-md;
          }

          small {
            font-size: $fs-12;
          }

          + button {
            fa-icon {
              font-size: $fs-16;
              color: $color;
            }

            &:hover {
              fa-icon {
                color: $color-red;
              }
            }
          }

          &.new {
            color: $color-brand-four;
          }

          &.error {
            color: $color-red;
          }

          &.read {
            color: transparentize($color, .5);
            @include transition (all 0.1s ease-in-out);
          }
        }

      }
    }

    .buttons {
      font-size: $fs-14;
      @include flex-justify-content(center);
    }
  }
}
