.table {
  width: 100%;
  /*height: 100%;*/
  font-size: $fs-12;
  background: white;
  position: relative;

  .scrollbar,
  &.scrollbar {
    padding: 0 0 2px 0;
  }

  ul {
    + ul {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }

  /* /// */

  .table-header,
  .table-content {
    width: 100%;
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content(space-between);

    > li {
      height: 35px;
      padding: 0 $table-padding;
      @include flexbox();
      @include flex-align-items(center);

      &:first-child {
        padding-left: calc(#{$table-padding * 2})
      }

      &:last-child {
        padding-right: calc(#{$table-padding * 2})
      }

      .item-name {
        padding: calc(#{$table-padding * 2}) 0;
        margin: calc(#{- $table-padding * 2}) 0;
        cursor: help;
      }
    }
  }

  .table-header {
    color: $color-gray;
    font-weight: 600;
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;

    .mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        font-size: $fs-12;
        padding: 0;
        border: none;
      }

      .mat-select-value {
        font-size: $fs-12;
        font-weight: 600;
        color: $color;
      }
    }
  }

  /* /// */

  .table-list {
    > li {
      width: 100%;
      background-color: $table-row-bg;
      /*ursor: pointer;*/
      @include transition(all 0.2s ease-in-out);
      @include flexbox();

      &:nth-child(even) {
        background-color: transparent;
      }

      &:hover {
        background-color: $table-row-bg-hover;
        @include transition(all 0.2s ease-in-out);
      }
    }

    /* /// */

    .buttons {
      margin: 0;

      li {
        + li {
          margin-left: $spacing-md;
        }
      }

      a {
        text-decoration: none;
        padding: 5px 0;
        @include flexbox();
        @include flex-align-items(center);
        cursor: pointer;

        &:hover {
          @include opacity(0.7);
        }

        fa-icon {
          font-size: 16px;
          padding-right: 5px;
        }
      }

      .history {color: $color-purple;}

      .preview {color: $color-blue;}

      .edit {color: $color-orange;}

      .stop, .delete {color: $color-red;}

      .cancel {color: $color-red;}

      .publish {color: $color-green;}
    }
  }

  /* /// */

  .table-content {
    &.error {
      color: $color-red;
    }

    .mat-form-field {
      .mat-form-field-wrapper {
        padding: 4px 8px;
      }
    }
  }

  /* /// */

  .delete {
    cursor: pointer;
    padding: 5px;
    @include transition(all 0.2s ease-in-out);

    &:hover {
      color: $color-three;
      @include transition(all 0.2s ease-in-out);
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////////// */
  /* COLUMNS */

  &.col-3 {
    .table-header,
    .table-content {
      > li {
        width: calc(100% / 3 - #{$table-padding *2} - #{$table-spacing});
      }
    }
  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* table smaller */

.table-smaller {
  @extend .table;

  .table-header,
  .table-content {
    > li {
      height: 30px;
    }
  }
}

/* ===================================================================================================================*/
/* ===================================================================================================================*/
/* ===================================================================================================================*/

/* table expansion */

.table {
  &.expansion {
    .table-header,
    .table-content {

      > li {
        width: calc(100% / 6);
      }
    }

    .table-header {
      width: calc(100% - #{$scrollbar-size} - 2px - 16px - #{$table-padding * 2} - #{$table-padding * 4});
      padding-right: calc(#{$scrollbar-size} + #{$scrollbar-spacing} + 2px);
      padding-left: calc(#{$table-padding * 4} + 16px);
    }

    /* /////////////////////////////////////////////// */

    .table-list {
      > li {
        .mat-checkbox {
          margin: calc(#{$table-padding * 2 - 1px}) 0 0 calc(#{$table-padding * 2});

          + .mat-expansion-panel {
            width: calc(100% - #{- $table-padding * 4} - 16px);

            + .mat-expansion-panel-header {
              padding-left: calc(#{- $table-padding * 2} - 16px);
              margin-left: calc(#{- $table-padding * 4} - 16px);
            }

            .table-content {
              > li {
                &:first-child {
                  padding-left: calc(#{$table-padding * 4});
                }
              }
            }
          }
        }
      }
    }

    /* /////////////////////////////////////////////// */

    .mat-expansion-panel {
      width: 100%;
      margin: 0;
      background: transparent;
      @include box-shadow(none!important);

      .mat-expansion-panel-header {
        font-size: inherit;
        padding: 0 10px 0 0;
      }

      .mat-expansion-panel-content {
        font-size: inherit;

        .more {
          @include flexbox();
          @include flex-align-items(center);

          ul {
            width: 100%;
            font-size: $fs-12;
            @include flexbox();

            li {
              width: calc(100% / 6 - #{$table-spacing * 2});
              padding: 0 $table-spacing;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }

              strong {
                display: block;
              }
            }
          }
        }

        /* /// */

        .settings {
          margin-top: $spacing-md;
          @include flexbox();
          @include flex-align-items(center);
          @include flex-justify-content(space-between);

          .edited {
            font-style: italic;
          }
        }

      }
    }

    /* ///////////////////////////// */

    &.unselectable {
      .table-header {
        width: calc(100% - #{$scrollbar-size} - #{$accordion-icon});
        padding-right: calc(#{$scrollbar-size} + #{$accordion-icon});
        padding-left: 0;
      }

      .table-list {
        > li {
          .mat-checkbox {
            margin: calc(#{$table-padding * 2 - 1px}) 0 0 calc(#{$table-padding * 2});

            + .mat-expansion-panel {
              width: calc(100% - #{- $table-padding * 4} - 16px);

              + .mat-expansion-panel-header {
                padding-left: calc(#{- $table-padding * 2} - 16px);
                margin-left: calc(#{- $table-padding * 4} - 16px);
              }

              .table-content {
                > li {
                  &:first-child {
                    padding-left: calc(#{$table-padding * 4});
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

mat-table {
  .mat-header-row {
    min-height: auto;
    padding: 0 0 calc(#{$main-padding / 2}) 0;
    border: none;

    .mat-header-cell {
      font-weight: 600 !important;
      color: $color-gray !important;
    }
  }

  .table-container {
    height: 400px;
  }

  .scrollbar {
    height: 230px;
  }

  mat-row {
    cursor: pointer;
    @include transition (all 0.2s ease-in-out);

    &:hover {
      background: $color-gray-lt;
      @include transition (all 0.2s ease-in-out);
    }

    &.highlight {
      @include themify('background', 'table-background-active');
      @include transition (all 0.2s ease-in-out);

      .mat-cell {
        @include themify('color', 'table-color-hover');
        @include transition (all 0.2s ease-in-out);
      }

      .mat-form-field {
        .mat-form-field-wrapper {
          background: transparent;
          border-color: white;
        }

        .mat-input-element {
          color: white;
        }
      }
    }

    &:focus {
      outline: none;
    }

    &.error {
      background: $color-red;
    }

    .mat-form-field {
      padding-top: 0;

      .mat-form-field-wrapper {
        max-width: 90%;
        padding: 4px 8px;
      }
    }
  }

  .img {
    width: 20px;
    height: 20px;
  }
}
