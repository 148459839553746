.aliquotas-icms-cup {
  .group-inline {
    @include flex-align-items(center);
  }

  /* ///////////////////////////////////////////////////// */

  .chart-choropleth {
    width: 50%;
    height: calc(100vh - 205px);
  }

  /* ///////////////////////////////////////////////////// */

  .list-icms {
    width: calc(45% - #{$spacing-lg});
    margin-left: $spacing-lg;

    .scrollbar {
      height: calc(100vh - 380px);
    }

    .table-header,
    .table-content {
      li {
        width: calc(40% - #{$table-spacing * 4});

        &:nth-child(1) {
          width: 60%;
        }
      }
    }

    mat-form-field {
      max-width: 70px;
    }
  }
}
