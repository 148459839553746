.register {
  &.register-drugstore {
    .table {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 10%;
          }

          &:nth-child(2) {
            width: calc(60% - #{$table-padding * 2 * 4});
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            width: 10%;
            @include flex-justify-content(center);
          }

          &:nth-child(6) {
            width: 15%;
          }

        }
      }
    }
  }
}
