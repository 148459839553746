.vtex {
  &.tratamento-pedidos-log {
    width: 900px;

    .scrollbar {
      height: 400px;
    }

    .table {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 10%;
          }

          &:nth-child(2) {
            width: calc(70% - #{$table-padding * 2 * 3})
          }

          &:nth-child(3) {
            width: 20%;
            text-align: center;
          }
        }
      }
    }
  }

}
