.dialog-desbloqueio-usuario-novo {
  width: 900px;

  .group-inline {
    margin-top: $spacing-sm;

    .group {
      width: 50%;

      + .group {
        width: calc(50% - #{$main-padding});
      }
    }
  }

  /* /////////////////////////// */

  .reason {
    textarea {
      min-height: 201px;
    }

    small {
      font-size: $fs-12;
    }
  }

  /* /////////////////////////// */

  .file-uploader {
    margin-left: $main-padding;

    > p {
      font-size: $fs-14;
      line-height: normal;
      margin-bottom: 3px;
    }

    .file-uploader-wrapper {
      max-height: 222px;
    }
  }
}
