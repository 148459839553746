.fechamento-caixa {
  &.calendario {

    .control {
      @include flexbox();

      > a {
        padding: 0 $spacing-sm;
      }
    }

    .calendar-events {
      .days {
        font-size: $fs-12;
        @include flexbox();
        @include flex-wrap(wrap);

        > li {
          width: calc(100% / 7 - #{$spacing-sm * 2} - 5px * 2 - 2px);
          height: 38px;
          padding: $spacing-sm;
          margin: 5px;
          border: $border-divider;
          position: relative;
          @include border-radius(4px);
          @include flexbox();
          @include flex-align-items(flex-end);
        }

        .done {
          background: transparentize($color-green, .8);
        }

        .warning {
          background: transparentize($color-red, .8);
        }

        .day {
          font-size: $fs-14;
          font-weight: 700;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }

  }
}
