.nps-filter {
  width: 1000px;

  .filter-period {
    margin-bottom: $spacing-md;

    ul {
      @include flexbox();
      @include flex-align-items(center);

      li {
        .mat-radio-label {
          padding: $spacing-sm;
          background: white;
          border: $border-divider;
          @include border-radius(4px);
          @include transition (all 0.2s ease-in-out);
        }

        .mat-radio-checked {
          .mat-radio-label {
            background: transparentize($color-green-lt, .8);
            @include transition (all 0.2s ease-in-out);
          }
        }

        + li {
          margin-left: $spacing-sm;
        }
      }
    }
  }

  /* //////////////////////////////////////////////////////////// */

  .branches {
    .scrollbar {
      height: 205px;
    }
  }

  .buttons{
    .buttonErro{
      color: #e02a31;
    }
  }


  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */

  @media screen and (min-width: $screen-xlg) {

    .branches {
      .scrollbar {
        height: 270px;
      }
    }

  }

}
