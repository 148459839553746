.dialog {
  &.fechamento-caixa-devolucoes-venda {
    width: 1100px;

    /* ////////////////////////////////////////////////////////////// */

    .mat-accordion {
      .mat-expansion-panel {
        mat-expansion-panel-header {
          padding: $spacing-sm $spacing-md;

          h2 {
            margin-right: $spacing-sm;
          }
        }

        + .mat-expansion-panel {
          margin-top: $spacing-sm;
        }
      }
    }

    /* ////////////////////////////////////////////////////////////// */

    .data-content {
      &.table {
        .scrollbar {
          height: 175px;
        }

        .table-header,
        .table-content {
          li {
            width: calc(75% / 9);

            &:nth-child(5) {
              width: 5%;
            }

            &:nth-child(9) {
              width: calc(20% - #{$table-spacing * 10});
            }
          }
        }
      }

      /* /// */

      .finance-total {
        font-size: $fs-12;
        margin: -10px 0;
        @include flexbox();
        @include flex-justify-content(flex-end);

        li {
          min-width: 115px;
          padding: 5px 10px;
          border: 2px dotted $color-gray-lt;
          background: white;
          @include border-radius(5px);

          + li {
            margin-left: $spacing-sm;
          }

          span {
            font-size: $fs-14;
            font-weight: 700;
            display: block;

            &.negative {
              color: $color-red;
            }
          }
        }
      }
    }
  }
}
