.desbloqueio-usuario {

  header {
    padding-bottom: $spacing-md;
  }

  .scrollbar {
    height: calc(100vh - 314px);
  }

  .table {
    .table-header,
    .table-content {
      li {
        width: calc(40% / 5);

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: calc(20% - #{$table-padding * 2 * 8});
        }
      }
    }
  }

}
