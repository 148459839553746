.client-base-data {
    font-size: $fs-12;
    height: 100%;
    @include flexbox();
    @include flex-direction(column);
    @include flex-justify-content-end();

  li {
    @include flexbox();
    @include flex-align-items(center);

    strong {
      width: 80px;
    }

    span {
      width: calc(100% - 80px - 10px);


      + span {
        padding-left: 5px;
        margin-left: 5px;
        border-left: $border-divider;
      }

      &.item-name {
        width: calc(100% - 100px);
      }

      &.highlight {
        font-weight: 500;
      }
    }

    .client-name {
      text-transform: lowercase;

      &::first-line {
        text-transform: capitalize;
      }
    }

    .client-star {
      @include themify('color', 'highlight-color');
      margin-left: auto;
      position: relative;
      cursor: help;

      .tooltip {
        top: 25px;
        right: -8px;
      }
    }
  }
}
