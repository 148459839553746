.multiple-selector {
  .header {
    font-size: $fs-14;
    margin: 0 10px 10px 10px;
  }

  .scrollbar {
    height: 300px;
  }

  /* /// */

  .ms-list,
  .ms-inline {
    @include flexbox();
  }

  /* /// */

  .ms-list {
    .group {
      .header {
        padding-bottom: $spacing-md;
        margin: 0 0 $spacing-md 0;
        border-bottom: $border-divider;

        p {
          height: 30px;
          @include flexbox();
          @include flex-align-items(center);
        }
      }
    }
  }

  /* /// */

  .list-buttons {
    li {
      margin: 10px;

      .mat-radio-button,
      .mat-checkbox {
        width: 100%;
        position: relative;

        .mat-radio-label,
        .mat-radio-label-content,
        .mat-checkbox-label {
          font-size: $fs-14 !important;
          text-align: center;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .mat-radio-label,
        .mat-checkbox-label {
          width: 80px;
          padding: 15px 30px;
          background: #f5f5f5;
          @include border-radius(2px);
          @include box-shadow(1px 1px 4px transparentize(black, 0.8));
          @include transition(all 0.2s ease-in-out);

          &:hover {
            @include transition(all 0.2s ease-in-out);
            background: white;
          }

          .mat-radio-label-content {
            margin: 0 auto;
          }
        }

        .mat-checkbox-inner-container {
          width: 20px;
          height: 20px;
          margin: -11px 0 0 0 !important;
          position: absolute;
          top: 50%;
          left: 7px;
        }

        .mat-radio-container,
        .mat-checkbox-frame {
          display: none;
        }

        .mat-radio-label-content {
          padding: 0;
        }

        &.mat-radio-checked,
        &.mat-checkbox-checked {
          .mat-radio-label,
          .mat-checkbox-label {
            color: white;
            background: $color-eight;
          }

          .mat-checkbox-background {
            background: transparent;
          }
        }
      }
    }

    &.ms-items,
    &.ms-subitems {
      @include flexbox();
      @include flex-direction(column);
    }

    &.ms-items {
      li {
        .mat-radio-checked,
        .mat-checkbox-checked {
          .mat-radio-label,
          .mat-checkbox-label {
            background: $color-six;
          }
        }
      }
    }

    &.ms-subitems {
      li {
        .mat-checkbox-checked {
          .mat-checkbox-label {
            background: $color-seven;
          }
        }
      }
    }
  }

  /* //////////////////////////////////////////// */

  .ms-options {
    width: 230px;
  }

  /* //////////////////////////////////////////// */

  .ms-result {
    width: calc(100% - 230px - #{$main-padding});
    padding-left: $spacing-md;
    margin-left: $spacing-md;
    border-left: $border-divider;
    position: relative;

    &:before {
      content: '';
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent $color-gray-lt;
      position: absolute;
      top: 50%;
      left: 0;
      margin: -10px 0 0 0;
    }

    .filter {
      .filter-search {
        width: 177px;
      }
    }

    .list-buttons {
      @include flexbox();
      @include flex-wrap(wrap);

      li {
        .mat-radio-button,
        .mat-checkbox {
          .mat-radio-label,
          .mat-checkbox-label {
            width: 100%;
            text-align: left;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */

  @media screen and (min-width: $screen-md) {
    .ms-result {
      .list-buttons {
        li {
          width: calc(100% / 2 - 20px);
        }
      }
    }
  }
}
