@import "~normalize.css/normalize.css";

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@import "theme/settings/colors";
@import "theme/settings/var";
@import "theme/settings/mixins";
@import "theme/settings/functions";
@import "theme/settings/font";
@import "theme/settings/layout";
@import "theme/settings/themify";

// Include the common and theme styles for Angular Material.
@include mat-core();
@include angular-material-theme($theme);

@import "theme/misc";
@import "theme/base/buttons";
@import "theme/base/form";
@import "theme/base/loading";
@import "theme/base/preloader";
@import "theme/base/scrollbar";

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@import "theme/components/chart/bar";
@import "theme/components/chart/line";
@import "theme/components/chart/donut";
@import "theme/components/chart/gauge";

@import "theme/components/sidenav/sidenav";
@import "theme/components/sidenav/sidenav-fixed";
@import "theme/components/sidenav/sidenav-item";

@import "theme/components/toolbar/toolbar-navigation";
@import "theme/components/toolbar/toolbar-navigation-dropdown-item";
@import "theme/components/toolbar/toolbar-notifications";
@import "theme/components/toolbar/toolbar-user-button";
@import "theme/components/toolbar/toolbar";

@import "theme/components/accordion";
@import "theme/components/address-grid";
@import "theme/components/branches";
@import "theme/components/breadcrumbs";
@import "theme/components/calculator";
@import "theme/components/change-history";
@import "theme/components/client-base-data";
@import "theme/components/dialog";
@import "theme/components/file-uploader";
@import "theme/components/modal";
@import "theme/components/multiple-selector";
@import "theme/components/phone-contact";
@import "theme/components/quickpanel";
@import "theme/components/search";
@import "theme/components/shortcut-keys";
@import "theme/components/stepper";
@import "theme/components/snack-bar";
@import "theme/components/table";
@import "theme/components/table-filter";
@import "theme/components/tabs";
@import "theme/components/tooltip";
