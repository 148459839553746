.dialog {
  &.rio-log-log-prefatura {
    width: 800px;

    .table {
      .scrollbar {
        height: 400px;

        .table-header,
        .table-content {
          li {
            width: calc(30% / 3);

            &:nth-child(3) {
              width: calc(70% - #{$table-spacing * 3});
            }
          }
        }
      }

    }
  }
}
