.cross-sell {
  &.cross-sell-search {
    .search-item {
      width: 500px;

      .table {
        .scrollbar {
          height: 215px;
        }

        .table-header,
        .table-content {
          li {
            width: calc(80% - #{$table-padding * 2 * 2});

            &:nth-child(1) {
              width: 20%;
            }
          }
        }

        .table-list {
          cursor: pointer;
        }
      }
    }

    /* //////////////////////// */

    .items-added {
      width: 300px;

      li {
        font-size: $fs-12;
        color: white;
        background: $color-blue-lt;
        padding: 10px 20px;
        @include border-radius(2px);
        @include flexbox();
        @include flex-justify-content(space-between);

        + li {
          margin-top: 15px;
        }

        a {
          padding: 10px 15px;
          margin: -10px -20px -10px 15px;
          border-left: 1px solid white;
        }

        span {
          width: 90%;
        }
      }
    }


  }
}
