.tooltip {
  min-width: 170px;
  font-size: $fs-12;
  font-weight: 500;
  color: white;
  padding: 20px;
  @include themify('background', 'tooltip-background');
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  @include flexbox();
  @include box-shadow(1px 1px 4px transparentize(black, .8));
  @include border-radius(4px);
  @include flex-align-items(center);
  @include transition (all 0.2s ease-in-out);
  @include opacity(0);
  visibility: hidden;

  &:before {
    content: "";
    width: 0;
    height: 0;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    left: -10px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    @include themify('border-color', 'tooltip-arrow');
  }

  .close {
    display: none;
  }

  &.visible {
    min-width: 180px;
    @include opacity(1);
    visibility: visible;
  }

  &.top {
    top: 35px;
    left: -20px;

    &:before {
      top: -15px;
      left: 20px;
      margin-top: 0;
      @include rotate(90deg);
    }

    &.right {
      left: auto;
      right: 0;

      &:before {
        left: auto;
        right: 10px;
      }
    }

    &.left {
      left: 0;

      &:before {
        left: 10px;
      }
    }
  }
}

button,
a,
p,
i,
fa-icon,
.item-name,
.input-name {
  position: relative;

  &:hover {
    + .tooltip {
      @include opacity(1);
      @include transition (all 0.2s ease-in-out);
      visibility: visible;
    }
  }
}

/* ////////////////////////// */

.mat-tooltip {
  font-size: $fs-12;
  font-weight: 500;
  color: white;
  background: linear-gradient(180deg, #2d2d2b 50%, #1d1d1b 50%);
  @include box-shadow(1px 1px 4px transparentize(black, .8));
  @include border-radius(4px!important);
}
