.mat-accordion {
  .mat-expansion-panel {
    color: $color;
    background: white;
    @include border-radius(2px!important);
    @include box-shadow(1px 1px 4px transparentize(black, .8)!important);

    &.mat-expansion-panel-spacing {
      margin: 0;
    }

    + .mat-expansion-panel {
      margin-top: $spacing-md;
    }

    .mat-expansion-panel-header {
      height: auto !important;
      @include themify('color', 'box-title-color');
      padding: $main-padding;
      @include transition (all 0.2s ease-in-out);

      h2 {
        font-size: $fs-14;
        margin: 0;
      }

      &:hover {
        background: transparentize(black, .98)!important;
        @include transition (all 0.2s ease-in-out);
      }

      &.cdk-focused {
        background: transparent!important;
        @include transition (all 0.2s ease-in-out);
      }

      .mat-expansion-indicator {
        &:after {
          @include themify('color', 'box-button-color');
        }
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: $spacing-md;
        border-top: $border-divider;
      }
    }
  }
}
