.painel-nfce-xml {
  pre {
    width: 70vw;
    height: 60vh;
    font-size: $fs-14;

    code {
      overflow-x: initial;
      @include user-select(text);
    }
  }
}
