.dialog {
  &.fechamento-caixa-valores-despesa {
    width: 1000px;

    .insert-data {
      .form-data {
        width: 60%;
      }

      /* /// */

      .import-upload {
        width: calc(40% - #{$main-padding});
        margin-left: $main-padding;

        .file-uploader {
          zoom: .8;;

          .file-uploader-wrapper {
            height: auto;
          }
        }
      }

      /* /// */

      textarea {
        height: 77px;
      }

      .buttons {
        @include flex-justify-content(center);

        fa-icon {
          margin-right: 5px;
        }
      }
    }

    /* /////////////////////////////////////////////////////// */

    .data-content {
      &.table {
        .scrollbar {
          height: 180px;
        }

        .table-header,
        .table-content {
          li {
            width: 15%;

            &:nth-child(5) {
              width: calc(35% - #{$table-spacing * 6});
            }

            .preview {
              color: $color-blue;

              fa-icon {
                margin-right: 5px;
              }
            }
          }
        }
      }

      /* /// */

      .finance-total {
        font-size: $fs-12;
        margin: -10px 0;
        @include flexbox();
        @include flex-justify-content(flex-end);

        li {
          min-width: 115px;
          padding: 5px 10px;
          border: 2px dotted $color-gray-lt;
          background: white;
          @include border-radius(5px);

          + li {
            margin-left: $spacing-sm;
          }

          span {
            font-size: $fs-14;
            font-weight: 700;
            display: block;

            &.negative {
              color: $color-red;
            }
          }
        }
      }
    }
  }
}
