.buttons {
  margin-top: $main-padding;
  position: relative;
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(flex-end);

  button,
  .button {
    min-width: 160px;
    font-size: $fs-14;
    font-weight: 500;
    line-height: normal;
    padding: 0 #{$button-height / 2};
    @include border-radius(4px);
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content(center);

    + button,
    + .button {
      margin-left: $spacing-sm;
    }

    fa-icon {
      font-size: $fs-16;
      line-height: 0;

      + span {
        margin-left: $spacing-sm;
      }
    }

    span {
      + fa-icon {
        margin-left: $spacing-sm;
      }
    }
  }

  /* /// */

  .mat-button-wrapper {
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content(center);
  }

  /* /// */

  p {
    @include themify('color', 'highlight-color');
    margin-right: $main-padding;
  }

  /* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .mat-raised-button {
    height: $button-height;
    border: 1px solid transparent;
    @include themify('color', 'button-text-color');
    @include themify('background', 'button-color');
    @include themify('border-color', 'button-color-border');
    @include transform(none); /* fix bug margin border */
    @include box-shadow(0 0 4px transparentize(black, .8));
    cursor: pointer;

    &:hover {
      background-position: 0 calc(#{$button-height - 2px})!important;
      @include themify('background', 'button-color-hover');
      @include themify('border-color', 'button-color-border');
    }

    /* ////////////// */
    /* STYLES */

    &:disabled,
    &.disabled {
      background: linear-gradient(170deg, #e7e7e7 50%, #e1e1e1 50%);
      border-color: $color-gray-lt;
      @include box-shadow(none);
      cursor: default;

      &:hover {
        background: linear-gradient(170deg, #e7e7e7 50%, #e1e1e1 50%);
        border-color: $color-gray-lt;
        background-position: 0 0!important;
      }
    }

    /* /// */

    &.bt-orange {
      background: linear-gradient(170deg, $color-orange-lt 50%, $color-orange 50%);
      border-color: $color-orange;

      &:hover {
        background: linear-gradient(170deg, $color-orange-lt 50%, $color-orange 50%);
        border-color: $color-orange;
      }
    }

    /* /// */

    &.bt-yellow {
      background: linear-gradient(170deg, $color-yellow-lt 50%, $color-yellow 50%);
      border-color: $color-yellow;

      &:hover {
        background: linear-gradient(170deg, $color-yellow-lt 50%, $color-yellow 50%);
        border-color: $color-yellow;
      }
    }

    /* /// */

    &.bt-red {
      background: linear-gradient(170deg, $color-red-lt 50%, $color-red 50%);
      border-color: $color-red;

      &:hover {
        background: linear-gradient(170deg, $color-red-lt 50%, $color-red 50%);
        border-color: $color-red;
      }
    }

    /* /// */

    &.bt-green {
      background: linear-gradient(170deg, $color-green-lt 50%, $color-green 50%);
      border-color: $color-green;

      &:hover {
        background: linear-gradient(170deg, $color-green-lt 50%, $color-green 50%);
        border-color: $color-green;
      }
    }

    /* /// */

    &.bt-blue {
      background: linear-gradient(170deg, $color-blue-lt 50%, $color-blue 50%);
      border-color: $color-blue;

      &:hover {
        background: linear-gradient(170deg, $color-blue-lt 50%, $color-blue 50%);
        border-color: $color-blue;
      }
    }

    /* /// */

    &.bt-purple {
      background: linear-gradient(170deg, $color-purple-lt 50%, $color-purple 50%);
      border-color: $color-purple;

      &:hover {
        background: linear-gradient(170deg, $color-purple-lt 50%, $color-purple 50%);
        border-color: $color-purple;
      }
    }

    /* /// */

    &.mat-icon-button {
      &:disabled {
        color: $color;
        background: linear-gradient(140deg, #eaeaea 50%, #e1e1e1 50%);
      }

      /* /// */

      &.bt-orange {
        background: linear-gradient(140deg, $color-orange-lt 50%, $color-orange 50%);

        &:hover {
          background: linear-gradient(140deg, $color-orange-lt 50%, $color-orange 50%);
        }
      }

      /* /// */

      &.bt-yellow {
        background: linear-gradient(140deg, $color-yellow-lt 50%, $color-yellow 50%);

        &:hover {
          background: linear-gradient(140deg, $color-yellow-lt 50%, $color-yellow 50%);
        }
      }

      /* /// */

      &.bt-red {
        background: linear-gradient(140deg, $color-red-lt 50%, $color-red 50%);

        &:hover {
          background: linear-gradient(140deg, $color-red-lt 50%, $color-red 50%);
        }
      }

      /* /// */

      &.bt-green {
        background: linear-gradient(140deg, $color-green-lt 50%, $color-green 50%);

        &:hover {
          background: linear-gradient(140deg, $color-green-lt 50%, $color-green 50%);
        }
      }

      /* /// */

      &.bt-blue {
        background: linear-gradient(140deg, $color-blue-lt 50%, $color-blue 50%);

        &:hover {
          background: linear-gradient(140deg, $color-blue-lt 50%, $color-blue 50%);
        }
      }

      /* /// */

      &.bt-purple {
        background: linear-gradient(140deg, $color-purple-lt 50%, $color-purple 50%);

        &:hover {
          background: linear-gradient(140deg, $color-purple-lt 50%, $color-purple 50%);
        }
      }
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .mat-stroked-button {
    height: $button-height;
    color: $color;
    white-space: normal;
    border: $border-divider;
    cursor: pointer;
    background: white;
    @include box-shadow(none);
    @include border-radius(4px);
    @include transition(all 0.2s ease-in-out);

    &:hover {
      background: transparentize($color-gray-xlt, .2);
      @include transition (all 0.2s ease-in-out);
    }

    /* /// */

    &:disabled {
      background: transparent;
      border-color: $color-gray-lt;
      @include opacity(.5);

      &:hover {
        background: transparent;
        border-color: $color-gray-lt;
      }
    }

    /* /// */

    &.bt-orange {
      color: $color-orange;
      border-color: $color-orange;

      &:hover {
        border-color: $color-orange-lt;
        background: transparentize($color-orange-lt, .95);
      }
    }

    /* /// */

    &.bt-red {
      color: $color-red;
      border-color: $color-red;

      &:hover {
        border-color: $color-red-lt;
        background: transparentize($color-red-lt, .95);
      }
    }

    /* /// */

    &.bt-green {
      color: $color-green;
      border-color: $color-green;

      &:hover {
        border-color: $color-green-lt;
        background: transparentize($color-green-lt, .95);
      }
    }

    /* /// */

    &.bt-blue {
      color: $color-blue;
      border-color: $color-blue;

      &:hover {
        border-color: $color-blue-lt;
        background: transparentize($color-blue-lt, .95);
      }
    }

    /* /// */

    &.bt-purple {
      color: $color-purple;
      border-color: $color-purple;

      &:hover {
        border-color: $color-purple-lt;
        background: transparentize($color-purple-lt, .95);
      }
    }

    /* /// */

    &.small {
      min-width: auto;
      font-size: $fs-12;
      padding: 0 calc(#{$button-height-sm / 2});
      height: $button-height-sm;

      fa-icon {
        font-size: $fs-14;
      }
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .mat-icon-button {
    min-width: auto;
    height: $button-height;
    color: $color;

    &:hover {
      background: $color-gray-xlt;
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .mat-button {
    min-width: auto;
    height: $button-height;

    &:hover {
      background: $color-gray-xlt;
    }
  }

  .mat-button-focus-overlay {
    display: none;
  }

}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

button,
.button {
  line-height: normal;
  cursor: pointer;
  @include transition (all 0.2s ease-in-out);

  &:hover {
    @include transition (all 0.2s ease-in-out);

    fa-icon {
      @include transition(all 0.2s ease-in-out);
    }
  }

  fa-icon {
    @include transition (all 0.2s ease-in-out);
  }

  &.mat-icon-button {
    background: transparent;
  }
}
