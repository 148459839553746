@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import 'https://fonts.googleapis.com/css?family=Roboto:400,500,700';
@import 'theme';
@import '~highlight.js/styles/github.css';

.mat-drawer-container {
  color: $color;
  background: $background;
}

@import "src/vendor/cropperjs/src/css/cropper";

@import 'styles/auth/login';
@import 'styles/auth/forgot';

@import 'styles/home';

/* pricing */
@import 'styles/contrato-verba/novo';
@import 'styles/contrato-verba/novo/importar';
@import 'styles/contrato-verba/consulta';
@import 'styles/contrato-verba/consulta/preview';
@import 'styles/contrato-verba/consulta/counting';
@import 'styles/contrato-verba/pesquisa';
@import 'styles/contrato-verba/relatorio';

@import 'styles/cross-sell/cross-sell';
@import 'styles/cross-sell/consulta';
@import 'styles/cross-sell/novo';
@import 'styles/cross-sell/pesquisa';

/* register */
@import 'styles/cadastro/lojas/register';
@import 'styles/cadastro/lojas/drugstore';
@import 'styles/cadastro/lojas/filial';
@import 'styles/cadastro/lojas/filial/cadastro';
@import 'styles/cadastro/lojas/filial/editar';
@import 'styles/cadastro/lojas/filial/consulta';

@import 'styles/cadastro/produtos/aliquotas-icms-cup';

/* suite */
@import 'styles/suite/importar';
  @import 'styles/suite/importacao/log';
@import 'styles/suite/exportar';

/* servicos */
@import 'styles/servicos/spot';
@import 'styles/servicos/pricefy';

/* support */
@import 'styles/suporte/desbloqueio-usuario';
@import 'styles/suporte/desbloqueio-usuario/novo';
@import 'styles/suporte/desbloqueio-usuario/editar';
@import 'styles/suporte/desbloqueio-usuario/autorizacao';
@import 'styles/nps/nps';
@import 'styles/nps/filter';

@import 'styles/painel-carga/painel-carga';
@import 'styles/painel-carga/estatisticas';

@import 'styles/painel-versao/painel-versao';
@import 'styles/painel-versao/branches';

@import 'styles/painel-nfce/painel-nfce';
@import 'styles/painel-nfce/consulta';
@import 'styles/painel-nfce/sefaz';
@import 'styles/painel-nfce/xml';
@import 'styles/painel-nfce/editar';

/* canais digitais */
@import 'styles/vtex/tratamento-pedidos';
@import 'styles/vtex/tratamento-pedidos/log';
@import 'styles/vtex/monitoramento';

@import 'styles/torre-controle/painel-gerencial';
  @import 'styles/torre-controle/painel-gerencial/sla-delivery-venda';
@import 'styles/torre-controle/painel-dinamico';
@import 'styles/torre-controle/chart';
@import 'styles/torre-controle/filter';

@import 'styles/parametrizacao/sla-pedidos';

@import 'styles/canais-digitais/fechamento-caixa/painel';
  @import 'styles/canais-digitais/fechamento-caixa/painel/modalidade';
  @import 'styles/canais-digitais/fechamento-caixa/painel/modalidade-multiplas';
  @import 'styles/canais-digitais/fechamento-caixa/painel/saldo-inicial';
  @import 'styles/canais-digitais/fechamento-caixa/painel/valor-remetido';
  @import 'styles/canais-digitais/fechamento-caixa/painel/devolucoes-venda';
  @import 'styles/canais-digitais/fechamento-caixa/painel/recebimentos-diversos';
  @import 'styles/canais-digitais/fechamento-caixa/painel/diferenca-remeter';
  @import 'styles/canais-digitais/fechamento-caixa/painel/valores-dinheiro';
  @import 'styles/canais-digitais/fechamento-caixa/painel/valores-despesa';
  @import 'styles/canais-digitais/fechamento-caixa/painel/valores-ncc-canceladas-dinheiro';
  @import 'styles/canais-digitais/fechamento-caixa/painel/valores-convenio';
  @import 'styles/canais-digitais/fechamento-caixa/painel/financeiro-recusado';
  @import 'styles/canais-digitais/fechamento-caixa/painel/financeiro-aprovado';
@import 'styles/canais-digitais/fechamento-caixa/calendario';

@import 'styles/canais-digitais/rio-log/painel';
  @import 'styles/canais-digitais/rio-log/painel/lancamento';
  @import 'styles/canais-digitais/rio-log/painel/visualizar';
  @import 'styles/canais-digitais/rio-log/painel/pesquisa-produtos';
  @import 'styles/canais-digitais/rio-log/painel/pesquisa-nota-fiscal';
  @import 'styles/canais-digitais/rio-log/painel/lista-protocolos';
  @import 'styles/canais-digitais/rio-log/painel/log-prefatura';
  @import 'styles/canais-digitais/rio-log/painel/log-saldo';
@import 'styles/canais-digitais/rio-log/carga-produtos';
  @import 'styles/canais-digitais/rio-log/carga-produtos/log';

/* configuracao */
@import 'styles/permissoes/permissoes';
@import 'styles/config/usuarios';

/* misc */

@import 'styles/misc';
@import 'styles/dialog';