.pricefy {

  .execute{
   display: inline;
  }

  .buttons{
    .buttonErro{
      color: #e02a31;
    }
  }

  .log {
    .scrollbar {
      height: calc(100vh - 360px);
    }
  }
}
