.calculator {
  width: 400px;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 99;
  background: white;
  @include transform(translate(-50%, -50%));

  .calculator-screen {
    width: calc(100% - 30px);
    height: 80px;
    font-size: $fs-80;
    text-align: right;
    padding: 0 $spacing-sm;
    border: none;
    @include themify('color', 'color-text-secondary');
    @include themify('background-color', 'toolbar-color');
  }

  .calculator-keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }

  button {
    height: 60px;
    font-size: $fs-22;
    color: #333;
    border: 1px solid transparentize($color-gray-lt, .5);

    &:hover {
      background-color: transparentize($color-gray-lt, .5);
    }

    &.operator {
      color: $color-blue;
    }

    &.clear {
      color: $color-white;
      background-color: $color-red;

      &:hover {
        background-color: $color-red;
      }
    }

    &.equal-sign {
      height: 100%;
      grid-area: 2 / 4 / 6 / 5;
      @include themify('background-color', 'button-color');
      @include themify('color', 'button-text-color');

      &:hover {
        @include themify('background-color', 'button-color-hover');
      }
    }
  }

}
