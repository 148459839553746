.fechamento-caixa {
  &.cpanel {

    .positive {color: $color-green;}

    .negative {color: $color-red;}

    .alert {color: $color-yellow;}

    a {
      cursor: pointer;
      &.view {color: $color-blue;}
      &.edit {color: $color-orange;}
    }

    .buttons {
      fa-icon {
        margin-right: 5px;
      }
    }

    /* ///////////////////////////////////////////////////////////// */

    .information {
      .header-title {
        h2 {
          margin: $spacing-sm 0 0 0;
        }
      }

      /* /// */

      .finance-total {
        font-size: $fs-14;
        @include flexbox();
        @include flex-justify-content(space-between);

        li {
          min-width: 150px;
          padding: 10px 15px;
          border: 3px dotted $color-gray-lt;
          background: white;
          @include border-radius(5px);

          + li {
            margin-left: $main-padding;
          }

          span {
            font-size: $fs-20;
            font-weight: 700;
            margin-top: 5px;
            @include flexbox();
            @include flex-align-items(center);

            &.negative {
              color: $color-red;
            }

            a {
              padding: 0 $spacing-sm;

              &.view {
                font-size: $fs-14;
              }
            }
          }

        }
      }
    }

    /* ///////////////////////////////////////////////////////////// */

    .cpanel-summary {
      > .box {
        @include flexbox();
        @include flex-justify-content(space-between);
      }

      /* //////////////////// */

      .filter {
        @extend .table-filter;
        margin: 0;
        padding: 0;
        border: none;

        li {
          width: 30%;

          &:last-child {
            width: calc(40% - #{$spacing-sm * 2});

            .buttons {
              @include flex-justify-content(flex-start);
            }
          }
        }
      }

      /* //////////////////// */

      .finance {
        @include flexbox();
        @include flex-justify-content(space-between);

        ul {
          width: 100%;
          @include flexbox();

          li {
            font-size: $fs-14;

            + li {
              padding-left: $spacing-md;
              margin-left: $spacing-md;
              border-left: $border-divider;
            }
          }

          span {
            font-size: $fs-16;
            font-weight: 700;
            @include flexbox();

            a {
              margin-top: -2px;
            }

            fa-icon {
              font-size: $fs-14;
              margin-left: $spacing-sm;
            }
          }
        }
      }
    }

    /* ///////////////////////////////////////////////////////////// */

    .cpanel-amount {
      padding: 0 $main-padding $main-padding $main-padding;
      @include flexbox();

      > section {
        width: calc(100% / 2 - #{$main-padding / 2});

        + section {
          margin-left: $main-padding;
        }
      }

      .content {
        min-height: calc(100vh - 490px);
        @include flexbox();
        @include flex-direction(column);
        @include flex-justify-content(space-between);
      }

      .table {
        .table-content {
          font-size: $fs-16;

          > li {
            width: auto;

            + li {
              font-weight: 700;
              margin-right: $spacing-sm;
            }
          }
        }

        /* /////////////////////// */

        .details {
          font-size: $fs-14;

          li {
            @include flexbox();
            @include flex-justify-content(space-between);

            + li {
              margin-top: $spacing-sm;
            }

            fa-icon {
              font-weight: normal;
              padding-left: $spacing-sm;
            }
          }
        }

        /* /////////////////////// */

        fa-icon {
          margin-right: $spacing-sm;
        }
      }

      /* //////////////////////// */

      .finance-resume {
        font-size: $fs-14;
        margin-top: $spacing-sm;
        @include flexbox();
        @include flex-justify-content(space-between);

        li {
          min-width: 150px;
          padding: 10px 15px;
          border: 3px dotted $color-gray-lt;
          @include border-radius(5px);

          span {
            font-size: $fs-16;
            font-weight: 700;
            margin-top: 5px;
            display: block;

            &.negative {
              color: $color-red;
            }
          }
        }
      }
    }

    /* ///////////////////////////////////////////////////////////// */

    footer {
      padding: 0 $main-padding $main-padding $main-padding;
      @include flexbox();
      @include flex-justify-content(flex-end);

      .erros {
        display: flex;
        align-self: flex-start;
        width: 100%;
        margin-top: 12px;
        color: #F00;
      }

      .buttons {
        margin: 0;
      }
    }

  }
}



