.torre-controle-filter {
  width: 1000px;

  .filter-settings {
    margin-bottom: 30px;
    @include flexbox();
    @include flex-align-items(flex-end);

    li {
      + li {
        margin-left: $spacing-sm;
        width: auto;
      }

      .label {
        font-size: $fs-14;
        margin-bottom: 4px;
      }

      .mat-radio-label {
        padding: $spacing-sm;
        background: white;
        border: $border-divider;
        @include border-radius(4px);
        @include transition (all 0.2s ease-in-out);
      }

      .mat-radio-checked {
        .mat-radio-label {
          background: transparentize($color-green-lt, .8);
          @include transition (all 0.2s ease-in-out);
        }
      }



      /* /// */



      /* /// */

      .mat-radio-group {
        padding-right: $spacing-sm;
        border-right: $border-divider;
        @include flexbox();
        @include flex-align-items(center);
      }

      .mat-radio-button {
        + .mat-radio-button {
          margin-left: $spacing-sm;
        }

        &.mat-radio-checked {
          .table-content {
            background: transparentize($color-green, .7);
          }
        }

        .mat-radio-label-content {
          padding: 0;
        }

        .mat-radio-container {
          display: none;
        }
      }

      /* /// */

      .fm-group {
        width: 150px;
      }

      /* /// */

      .filter-select {
        min-width: 120px;
      }
    }

    /* /////////////// */

    .mat-radio-button,
    .mat-radio-label-content {
      width: 100%;
    }
  }


  /* //////////////////////////////////////////////////////////// */

  .branches {
    .scrollbar {
      height: 205px;
    }
  }

  /* //////////////////////////////////////////////////////////// */

  .table {
    &.regional {

      &.scrollbar {
        height: 345px;
      }

      .mat-radio-button,
      .mat-radio-label-content {
        width: 100%;
      }

      .mat-radio-button {
        &.mat-radio-checked {
          .table-content {
            background: transparentize($color-green, .7);
          }
        }

        .mat-radio-container {
          display: none;
        }

        .mat-radio-label-content {
          padding-left: 0;
        }
      }

      .table-header,
      .table-content {
        li {
          width: 10%;

          &:nth-child(2) {
            width: calc(40% - #{$table-spacing * 16});
          }
        }
      }

    }
  }

  .buttons{
    .buttonErro{
      color: #e02a31;
    }
  }



  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */

  @media screen and (min-width: $screen-xlg) {

    .branches {
      .scrollbar {
        height: 270px;
      }
    }

  }

}
