sidenav-fixed,
.sidenav-fixed {
  width: $sidenav-fixed-size-w;
  height: $sidenav-fixed-size-h;
  background: $background;
  position: relative;
  @include flexbox();
  @include flex-direction(column);
  @include flex-justify-content(center);
  @include box-shadow(1px 1px 4px transparentize(black, .7));

  + .main {
    width: calc(100% - #{$sidenav-fixed-size-w});
  }

  ul {
    height: $sidenav-fixed-size-h;
    z-index: 2;
    position: relative;
    @include flexbox();
    @include flex-direction(column);

    &.fixed {
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 1;
    }
  }

  li {
    height: $sidenav-fixed-size-bt;
    position: relative;
    @include flexbox();

    img {
      width: 100%;
    }
  }

  button {
    width: 100%;
    height: 100%;
    font-size: $icon-size;
    border: none;
    cursor: pointer;
    @include box-shadow(0 0 4px transparentize(black, .8));
    @include themify('color', 'sidenav-fixed-color');
    @include themify('background', 'sidenav-fixed-background');
    @include transition (all 0.2s ease-in-out);

    &:hover {
      @include themify('background', 'sidenav-fixed-background-hover');
      background-position: 0 $sidenav-fixed-size-bt-hover!important;
      @include transition (all 0.2s ease-in-out);
    }

    &.disabled {
      background: $color-gray;
    }
  }

  i, fa-icon {
    img, svg {
      max-width: $icon-size;
      max-height: $icon-size;
      @include themify('fill', 'sidenav-fixed-color');
    }
  }

  .material-icons {
    font-size: calc(#{$icon-size} + 6px);
  }

  .tooltip {
    height: 100%;
    padding: 0 20px;
    left: $sidenav-fixed-size-w;
  }

  /* ///////////////////////////////////////////////////////////////////////// */
  /* Custom Height Items */

  .item-4 {
    li {
      height: calc(100vh / 4);

      button {
        &:hover {
          background-position: 0 calc(100vh / 4 - 10px) !important;
        }
      }
    }
  }

  .item-5 {
    li {
      height: calc(100vh / 5);

      button {
        &:hover {
          background-position: 0 calc(100vh / 5 - 5px) !important;
        }
      }
    }
  }

  /* /// */

  .item-6 {
    li {
      height: calc(100vh / 6);

      button {
        &:hover {
          background-position: 0 calc(100vh / 6 - 5px) !important;
        }
      }
    }
  }

}
