.spot {
  .fm-inline {
    .buttons {
      margin-left: auto;
    }
  }

  .log {
    .scrollbar {
      height: calc(100vh - 360px);
    }
  }
}
