.painel-nfce-edit {
  width: 1200px;

  textarea {
    &.mat-input-element {
      height: 100px;
    }
  }

  /* ///////////////////////// */

  .painel-nfce-edit-products {
    .scrollbar {
      max-height: 50vh;
    }

    .table {
      .table-header,
      .table-content {
        li {
          width: calc(45% / 4);
          @include flex-justify-content(center);

          &:nth-child(1) {
            width: 5%;
            @include flex-justify-content(flex-start);
          }

          &:nth-child(2) {
            width: calc(50% - #{$table-spacing * 6});
            @include flex-justify-content(flex-start);
          }
        }
      }
    }

    .more {
      @include flex-direction(column);

      /* /////////// */

      fieldset {
        width: 100%;

        + fieldset {
          margin-top: $spacing-md;
          padding-top: $spacing-md;
          border-top: $border-divider;
        }

        > p {
          font-weight: 600;
          line-height: normal;
          margin-bottom: $spacing-md;
        }

        .fm-group {
          zoom: .8;
        }
      }
    }
  }
}
