.budget-contract {
  &.budget-contract-import {
    width: 1200px;
    height: calc(90vh - 90px);

    .options {
      .buttons {
        @include flex-justify-content(flex-start);
      }
    }

    .file-uploader {
      height: 100%;

      .file-uploader-wrapper {
        height: 100%;
      }
    }

    .import-settings {
      padding: 0;
      margin-top: $main-padding;
      @include flexbox();

      .import-upload {
        width: 40%;
        margin-right: $main-padding;
      }

      .import-details {
        width: calc(60% - #{$main-padding});
      }
    }

    /* ////////////////////////////// */

    .scrollbar {
      height: calc(100vh - 495px);
    }


    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-xlg) {
      .scrollbar {
        height: calc(100vh - 520px);
      }
    }

  }
}
