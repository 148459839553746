.budget-contract {
  &.contract-counting {
    width: 600px;

    .table {
      .table-header,
      .table-content {
        li {
          text-align: center;
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 25%;
          }
          &:nth-child(5) {
            width: 25%;
          }
        }
      }
    }
    .contract-info {
      font-size: $fs-14;

      ul {
        @include columns(3);

        li {
          margin-bottom: $spacing-md;
        }
      }

      strong {
        display: block;
        margin-bottom: 5px;
      }
    }

    /* ///////////////////////////////// */

    .contract-options {
      height: 475px;
      padding: $spacing-md;
      margin-top: $spacing-md;
      background: $background;
      @include border-radius(4px);

      .scrollbar {
        height: 280px;
      }

      ol {
        @include flexbox();
        @include flex-align-items(center);
        @include flex-wrap(wrap);

        li {
          margin: $spacing-sm;
          @include flexbox();
        }

        span {
          min-width: 80px;
          font-size: $fs-14 !important;
          text-align: center;
          padding: 15px 30px;
          background: #f5f5f5;
          @include border-radius(2px);
          @include box-shadow(1px 1px 4px transparentize(black, 0.8));
          @include transition(all 0.2s ease-in-out);
        }
      }
    }
  }
}
