.shortcut-keys {
  width: 900px;

  .group {
    width: 30%;

    + .group {
      width: calc(70% - #{$main-padding});
      margin-left: $main-padding;
    }
  }

  kbd {
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    background-color: $color-gray-xlt;
    border: 1px solid #b4b4b4;
    display: inline-block;
    white-space: nowrap;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
    @include border-radius(3px);
  }

  .shortcut-screens {
    ul {
      @include flexbox();
      @include flex-wrap(wrap);

      li {
        width: calc(100% / 2 - #{$main-padding / 2});

        &:nth-child(even) {
          margin-left: $main-padding;
        }

        &:nth-child(2) {
          border: none;
          padding: 0;
          margin-top: 0;
        }
      }
    }
  }

  li {
    font-size: $fs-14;

    + li {
      padding-top: $spacing-sm;
      margin-top: $spacing-sm;
      border-top: $border-divider;
    }

    p {
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

}
