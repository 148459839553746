.dialog-version-panel-branches {
  .table {
    .table-header,
    .table-content {
      li {
        width: 20%;
        text-align: left;

        &:nth-child(1) {
          width: 30px;
        }

        &:nth-child(2) {
          width: calc(60% - #{$table-padding} - 30px);
        }
      }
    }

    .up-to-date {
      fa-icon {
        color: $color-brand-three;
      }
    }

    .attention {
      fa-icon {
        color: $color-brand-one;
      }
    }

    .deprecated {
      fa-icon {
        color: $color-red;
      }
    }
  }

  .scrollbar {
    height: 50vh;
  }
}
