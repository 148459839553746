.dialog {
  &.fechamento-caixa-modalidade {
    width: 1200px;

    .positive {color: $color-green;}
    .negative {color: $color-red;}
    .alert {color: $color-yellow;}

    .table {
      .scrollbar {
        height: 350px;
      }

      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 2%;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(12) {
            width: 5%;
          }

          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            width: 5%;
          }
          &:nth-child(9) {
            width: calc(12% - #{$table-spacing * 12});
          }
          &:nth-child(10) {
            width: 13%;

            span {
              a {
                margin-right: 6px;
                font-size: 18px;
              }
            }
          }

          &:nth-child(11) {
            width: 16%; 

            mat-form-field {
              width: 100%;
            }
          }

          .mat-input-element,
          .mat-select-value {
            font-size: $fs-12;
          }

          mat-form-field {
            + a {
              font-size: $fs-14;
              margin-left: 10px;

              &.edit {
                color: $color-orange;
              }
            }
          }

        }
      }
    }
  }
}
