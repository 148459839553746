$color-brand-one   : #ebaf23 !default;
$color-brand-two   : #f06423 !default;
$color-brand-three : #71bf4b !default;
$color-brand-four  : #006cb7 !default;

$color-white     : #ffffff !default;
$color-black     : #000000 !default;
$color-gray      : #656565 !default;
$color-gray-xlt  : #eeeeee !default;
$color-gray-lt   : #e1e1e1 !default;
$color-gray-md   : #999999 !default;
$color-gray-hv   : #4a4b4d !default;
$color-blue-lt   : #0777c5 !default;
$color-blue      : #006cb7 !default;
$color-blue-hv   : #254268 !default;
$color-green-lt  : #77c451 !default;
$color-green     : #71bf4b !default;
$color-green-hv  : #009248 !default;
$color-red-lt    : #e7383e !default;
$color-red       : #e02a31 !default;
$color-red-hv    : #b31319 !default;
$color-yellow-lt : #f5b829 !default;
$color-yellow    : #ebaf23 !default;
$color-yellow-hv : #dbc32b !default;
$color-orange-lt : #f57236 !default;
$color-orange    : #f06423 !default;
$color-orange-hv : #dc7612 !default;
$color-purple-lt : #a50464 !default;
$color-purple    : #96005A !default;
$color-pink      : #fe93b0 !default;

$color-one   : #fab727 !default;
$color-two   : #f48925 !default;
$color-three : #d0232a !default;
$color-four  : #9a1740 !default;
$color-five  : #86266b !default;
$color-six   : #2674ae !default;
$color-seven : #2d86c8 !default;
$color-eight : #4cb757 !default;

$color-error : #e02a31 !default;

$color      : #555555 !default;
$background : #f5f5f5 !default;

$toolbar-bg : #231f20 !default;
$breadcrumb-bg : #ededed !default;

$dialog-background : #f6f6f6 !default;

$scrollbar-color-background: #e0e0e0 !default;
$scrollbar-color-track: #777777 !default;

$sidenav-bg : #ffffff !default;
$sidenav-bg-hover : #e8e8e8 !default;
$sidenav-sub-item-bg : #f5f5f5 !default;

$table-row-bg       : #f5f5f5 !default;
$table-row-bg-hover : #e8e8e8 !default;

$disabled-text       : #b1b1b1 !default;
$disabled-background : #e1e1e1 !default;
