.painel-carga {

  .dashboard {
    ul {
      @include flexbox();
      @include flex-align-items(center);
      @include flex-wrap(wrap);
    }

    li {
      margin: 0 5px 10px 5px;

      &:nth-child(1) {fa-icon {color: $color-one;}}
      &:nth-child(2) {fa-icon {color: $color-two;}}
      &:nth-child(3) {fa-icon {color: $color-three;}}
      &:nth-child(4) {fa-icon {color: $color-four;}}
      &:nth-child(5) {fa-icon {color: $color-five;}}
      &:nth-child(6) {fa-icon {color: $color-six;}}
      &:nth-child(7) {fa-icon {color: $color-seven;}}
      &:nth-child(8) {fa-icon {color: $color-eight;}}

      &:last-child {
        margin-right: 0;
      }

      .mat-checkbox {
        width: 100%;
        position: relative;

        .mat-checkbox-label {
          width: 70px;
          height: 70px;
          @extend .box;
          @include flexbox();
          @include flex-direction(column);
          @include flex-align-items(center);
          @include flex-justify-content(center);

          fa-icon {
            font-size: $fs-30;
            @include transition(all 0.2s ease-in-out);
          }

          span {
            margin-top: $spacing-sm;
            @include transition(all 0.2s ease-in-out);
          }

          &:hover {
            fa-icon, span {
              @include opacity(0.7);
              @include transition(all 0.2s ease-in-out);
            }
          }
        }

        .mat-checkbox-inner-container {
          width: 25px;
          height: 25px;
          margin: 0 !important;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }

        .mat-checkbox-frame {
          display: none;
        }

        &.mat-checkbox-checked {
          .mat-checkbox-background {
            background: $color-green;
          }
        }
      }
    }
  }

  /* /////////////////////////////////////////////////////////// */

  .log {
    .content {
      @include flexbox();
      @include flex-align-items(center);
    }

    .scrollbar {
      height: 390px;
    }

    .pipeline {
      width: 25%;

      .table-filter {
        margin-bottom: 0;
        border: none;
      }

      li {
        span {
          width: calc(100% - 30px - #{$spacing-sm});
        }

        &.done {
          color: $color-green;
        }

        &.error {
          color: $color-red;
        }

        &.running {
          color: $color-blue;

          fa-icon {
            @include spinner-rotate(2s linear infinite);
          }
        }
      }

      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 25px;

            fa-icon {
              width: 25px;
              height: 25px;
              line-height: 0;
              @include flexbox();
              @include flex-align-items(center);
              @include flex-justify-content(center);
              @include border-radius(100rem);
            }
          }

          &:nth-child(2) {
            width: calc(100% - 25px - 25px - #{$table-padding * 2 * 3});
          }

          &:nth-child(3) {
            width: 25px;
            color: $color;
          }

          .item-name {
            cursor: pointer;
          }

          a {
            padding: 0 $spacing-sm;
            cursor: pointer;
          }
        }
      }

      + .events {
        padding-left: $spacing-md;
        margin-left: $spacing-md;
        border-left: $border-divider;
      }
    }

    /* //////////////////////// */

    .events {
      width: calc(75% - #{$spacing-md * 2} - 1px);

      /*.table-header,
      .table-content {
        li {
          width: 10%;

          &:nth-child(1) {
            width: calc(80% - #{$table-padding * 2 * 3})
          }
        }
      }*/
    }
  }

}
