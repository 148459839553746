.import {
  .import-settings {
    height: calc(100vh - 200px - 60px);
    margin-top: $main-padding;
    @include flexbox();

    .options {
      margin-bottom: $spacing-md;
    }

    .buttons {
      margin-top: 15px;

      fa-icon {
        margin-right: 10px;
      }
    }
  }

  /* ////////////////// */

  .import-type {
    width: 50%;
    @include flexbox();
    @include flex-direction(column);
    @include flex-justify-content(flex-start);
  }

  .import-parameters {
    height: 100%;

    .scrollbar {
      height: calc(35vh - 40px);
      padding-left: 10px;
    }

    .table {
      ul {
        li {
          overflow-wrap: anywhere;

          + li {
            margin-top: $spacing-sm;
          }
        }
      }
    }
  }

  /* ////////////////// */

  .import-upload {
    width: calc(50% - #{$main-padding});
    margin-left: $main-padding;

    .file-uploader {
      height: 100%;

      .file-uploader-wrapper{
        height: 100%;
      }
    }
  }

  /* ////////////////// */

  footer {
    margin-top: $spacing-md;
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content(space-between);

    .process-steps {
      @include flexbox();
      @include flex-align-items(center);

      li {
        @include opacity(.4);
        @include flexbox();
        @include flex-align-items(center);

        > div {
          @include flexbox();
          @include flex-align-items(center);
        }

        + li {
          padding-left: 100px;
          margin-left: $spacing-sm;
          position: relative;

          &:before {
            content: "";
            width: calc(100px - #{$spacing-sm});
            height: 1px;
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
            background: $color-gray;
          }
        }

        fa-icon {
          font-size: $fs-24;
          margin-right: $spacing-sm;

          &.spinner {
            width: 23px;
            height: 23px;
            background: $color-blue;
            color: white;
            font-size: $fs-14;
            @include spinner-rotate(1.5s linear infinite);
            @include border-radius(100rem);
            @include flexbox();
            @include flex-align-items(center);
            @include flex-justify-content(center);
          }
        }

        &.active {
          color: $color-blue;
          @include opacity(1);

          &:before {
            background: $color-green;
          }
        }

        &.error {
          color: $color-red;
          @include opacity(1);

          &:before {
            background: $color-red;
          }
        }

        &.done {
          color: $color-green;
          @include opacity(1);

          &:before {
            background: $color-green;
          }
        }
      }
    }

    /* /// */

    .buttons {
      margin-top: 0;
    }
  }

}
