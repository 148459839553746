.sidenav-item {
  height: 48px;
  font-size: $fs-14;
  text-decoration: none;
  color: $color;
  padding: $sidenav-padding;
  position: relative;
  cursor: pointer;
  @include transition (all 0.15s ease-in-out);
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(space-between);

  .item {
    @include flexbox();
    @include flex-align-items(center);

    .icon {
      width: 40px;
      height: 40px;
      font-size: $fs-18;
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(center);
    }
  }

  &:hover {
    color: $color;
    @include transition (all 0.15s ease-in-out);

    .icon {
      @include transition (all 0.15s ease-in-out);
    }
  }

  &.active {
    color: $color;
    font-weight: 500;

    i {
      &.icon {
        color: $color;
      }
    }
  }

  .letter-icon {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    margin-right: 5px;
  }

  .dropdown-indicator {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-right: 6px;
    transform: rotate(-90deg);
    transition: transform .25s cubic-bezier(.25,.8,.25,1);

    &.rotate {
      transform: rotate(0);
    }
  }

  &:focus {
    outline: none;
  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.sidenav-item-container {
  .sub-menu {
    padding: 0;
    background: white;
    overflow: hidden;
    @include transition(height .4s cubic-bezier(.35, 0, .25, 1));

    &:before,
    &:after {
      content: " ";
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      z-index: 3;
    }

    &:before {
      background: linear-gradient(180deg, rgba(0,0,0,.1) 0, transparent);
      top: 0;
    }

    &:after {
      background: linear-gradient(-180deg, rgba(0,0,0,.1) 0, transparent);
      bottom: -1px;
    }
  }

  /* /////////////////////////////// */

  > .sidenav-item {
    &:hover {
      background: $sidenav-bg-hover;
    }

    &.active {
      background: $sidenav-bg-hover;
    }

    /* ////////////////////// */
    /* lvl 1 */

    + .sub-menu {
      position: relative;

      .sidenav-item-container {
        > .sidenav-item {
          padding-left: $sidenav-item-padding;
          background-color: transparentize(black, 0.96);

          &:hover {background-color: $sidenav-bg-hover;}
          &.active {background-color: $sidenav-bg-hover;}

          /*&:before {
            content: "";
            width: 10px;
            height: 1px;
            margin-left: -15px;
            background: gray;
            position: absolute;
          }

          &.open {
            &:before {
              content: "";
              width: 1px;
              height: 10px;
              margin-left: -15px;
              background: gray;
              position: absolute;
            }
          }*/

          /* ////////////////////// */
          /* lvl 2 */

          + .sub-menu {
            position: relative;

            .sidenav-item-container {
              > .sidenav-item {
                padding-left: calc(#{$sidenav-item-padding} + 10px);
                background: transparentize(black, 0.94);

                &:hover {background-color: transparentize(black, 0.9);}
                &.active {background-color: transparentize(black, 0.9);}

                .letter-icon {
                  display: none;
                }
              }
            }
          }

        }
      }
    }

  }
}
