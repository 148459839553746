.rio-log {
  &.cpanel {

    .positive {color: $color-green;}
    .negative {color: $color-red;}
    .alert {color: $color-yellow;}
    .progress {color: $color-blue;}

    a {
      cursor: pointer;

      &.spreadsheet {color: $color-orange;}
      &.preview {color: $color-blue;}
    }


    /* ///////////////////////////////////////////////////////////// */

    .table {
      h2 {
        margin: 0 0 0 $spacing-md;
      }

      .filter-datepicker {
        max-width: 120px;
      }

      .actions {
        font-size: $fs-16;
        @include flexbox();

        a {
          padding: 5px 10px;
        }
      }
    }

    /* ///////////////////////////////////////////////////////////// */

    .table {
      .scrollbar {
        height: calc(100vh - 372px);
      }

      .table-header,
      .table-content {
        > li {
          width: calc(65% / 8);

          &:nth-child(1) {
            width: 2%;
            font-size: $fs-14;
          }

          &:nth-child(3),
          &:nth-child(8) {
            width: calc(28% - #{$table-spacing * 10});
          }

          &:nth-child(9) {
            width: 5%;
          }

          &:nth-child(10) {
            @include flex-justify-content(flex-end);
          }
        }
      }
    }

    /* ///////////////////////////////////////////////////////////// */

    footer {
      padding: 0 $main-padding $main-padding $main-padding;
      @include flexbox();
      @include flex-justify-content(flex-end);

      .buttons {
        margin: 0;
      }
    }

  }
}



