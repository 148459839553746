.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0;
  display: none;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
  margin-top: $spacing-sm;
}

.fm-group {
  width: 100%;
  padding-top: 20px;
  display: block !important;
  position: relative;

  + .fm-group,
  + .fm-inline {
    margin-top: $spacing-sm;
  }
}

.fm-inline {
  @include flexbox();
  @include flex-align-items(baseline);

  + .fm-inline,
  + .fm-group {
    margin-top: $spacing-sm;
  }

  .fm-group {
    width: 100%;
    margin: 0;
    display: inline-block !important;

    &:last-child {
      margin: 0;
    }

    + .fm-group {
      margin: 0 0 0 $spacing-sm;
    }
  }

  + .group-inline {
    margin-top: $spacing-sm;
  }
}


  
    
  


.fm-select {
  @include flexbox();
  @include flex-align-items(center);

  i {
    margin-right: 1rem;
  }
}

label {
  fa-icon {
    margin-right: 5px;
  }
}

/* ////////////////////////////////////////////////////// */

.mat-form-field-appearance-legacy {
  .mat-form-field-label-wrapper {
    font-size: $fs-14;
    top: -30px;
    left: -10px;
    padding: 0;
  }

  .mat-form-field-label {
    width: 100% !important;
    color: $color;
    @include transform(translateY(-18px) !important);
  }

  .mat-input-element {
    font-size: $fs-14;
    color: $color;
    height: 100%;
    padding: $form-field-padding 0;
    margin: calc(-#{$form-field-padding}) 0;
    box-sizing: border-box;

    &:disabled {
      color: transparentize(black, .62);
    }
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon-button {
      width: auto;
      height: auto;
      padding: 8px;
      margin: -8px;

      svg {
        height: auto;
      }
    }
  }

  .mat-form-field-prefix {
    font-size: $fs-12;
    margin-right: 5px;

    + .mat-form-field-infix {
      .mat-form-field-label-wrapper {
        left: -33px;
      }
    }
  }

  .mat-form-field-suffix {
    @include themify('color', 'form-suffix-prefix');
    cursor: pointer;

    button,
    .button {
      @include themify('color', 'form-suffix-prefix');
      background: none;
      border: none;
      cursor: pointer;

      &:disabled,
      &.disabled {
        color: $color-gray-lt;
        cursor: default;
      }
    }
  }

  .mat-form-field-subscript-wrapper {
    width: auto;
    /*width: calc(100% - #{$form-field-padding * 2});*/
    margin: 0;
    top: 2px;
    right: 5px;
  }

  .mat-form-field-infix {
    width: 100%;
    padding: 0;
    border-top: none;
  }
}
  
    
  


.mat-form-field {
  line-height: normal;

  .mat-form-field-wrapper {
    padding: 8px;
    border: 1px solid transparentize(black, 0.8);
    background: white;
    @include border-radius(4px);
    @include transition(all 0.2s ease-in-out);
  }

  &.mat-focused {
    .mat-form-field-label {
      @include themify('color', 'brand-primary');
      @include transition(all 0.2s ease-in-out);
    }

    .mat-form-field-wrapper {
      @include themify('border-color', 'brand-primary');
      @include transition(all 0.2s ease-in-out);
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: $color-error;
    }

    .mat-form-field-wrapper {
      border-color: $color-error;
      @include transition(all 0.2s ease-in-out);
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      border-style: dashed;
      background: transparentize($color, 0.95);
    }
  }

  &.readonly {
    .mat-form-field-wrapper {
      border-style: dashed;
    }
  }

  mat-error {
    font-size: $fs-10;
    color: $color-error;
  }
}

/* ////////////////////////////////////////////////////// */
/* input */

input {
  /*&:-webkit-autofill,
  &:-webkit-autofill::first-line,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: $color;
    font: 400 $fs-14 'Roboto' !important;
    @include transition(background-color 5000s ease-in-out 0s);
  }*/

  /* placeholder */
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: transparentize($color, .5);
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: transparentize($color, .5);
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: transparentize($color, .5);
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: transparentize($color, .5);
  }

  /* readonly */
  &:read-only {
    cursor: default;
  }

  /* number */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

/* ////////////////////////////////////////////////////// */
/* file upload */

input[type='file'] {
  display: none;
}

/* ////////////////////////////////////////////////////// */
/* search */

.fm-search {
  width: 100%;
  @include flexbox();
  @include flex-align-items(center);

  .fm-group {
    margin: 0;
  }

  button {
    width: auto;
    height: auto;
    line-height: normal;
    margin: 0 15px 15px 0;

    .mat-icon {
      font-size: $fs-18;
    }
  }
}

/* ////////////////////////////////////////////////////// */
/* password */

.fm-password {
  position: relative;

  input {
    padding-right: 30px;
  }

  span {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

/* ////////////////////////////////////////////////////// */
/* select */

mat-select {
  padding: $form-field-padding 0;
  margin: calc(-#{$form-field-padding}) 0;
}

.mat-select-panel {
  @extend .scrollbar;
  padding-right: 0!important;
}

.mat-select-value {
  font-size: $fs-14;
  color: $color;
  background: transparent;
}

.mat-select-arrow {
  @include themify('color', 'form-suffix-prefix');
}

/* ///////////////////// */
/* toggle */

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-bar {
      @include themify('background', 'form-toggle-background');
      @include opacity(.7);
    }

    .mat-slide-toggle-thumb {
      @include themify('background', 'form-toggle');
    }
  }

  .mat-slide-toggle-content {
    font-size: $fs-14;
    font-weight: 400;
    color: $color;
  }
}

/* ///////////////////// */
/* radio */

.mat-radio-button {
  &.mat-accent {
    @include flexbox();

    &.mat-radio-checked {
      .mat-radio-inner-circle {
        @include themify('background', 'brand-primary');
      }

      .mat-radio-outer-circle {
        @include themify('border-color', 'brand-primary');
      }
    }
  }
}

.mat-radio-container {
  zoom: 0.8;
}

.mat-radio-label {
  font-size: $fs-12;
}

.fm-radio-inline {
  mat-radio-button {
    + mat-radio-button {
      margin-left: $spacing-sm;
    }
  }
}

/* ////////////////////////////////////////////////////// */
/* checkbox */

.mat-checkbox {
  .mat-checkbox-inner-container {
    margin: 0 calc(#{$main-padding / 2}) 0 0;
  }

  &.mat-checkbox-checked {
    &.mat-accent {
      .mat-checkbox-background {
        @include themify('background', 'brand-primary');
      }
    }
  }

  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      @include themify('background', 'brand-primary');
    }
  }
}

.mat-checkbox-layout {
  display: -webkit-flex !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -moz-box !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-checkbox-label {
  font-size: $fs-12 !important;
  line-height: normal !important;
}




/* ////////////////////////////////////////////////////// */
/* datepicker */

.ngx-mat-timepicker {
  .ngx-mat-timepicker-table {
    .mat-form-field {
      width: 40px !important;
      max-width: 40px !important;
    }
  }
}

/* ////////////////////////////////////////////////////// */
/* textarea */

textarea {
  resize: none !important;
  box-sizing: border-box !important;
  /*padding: 8px;
  border: 1px solid transparentize(black, 0.8);
  background: white;
  @include border-radius(4px);
  @include transition(all 0.2s ease-in-out);*/

  /*&:focus {
    border-color: $color-blue;
    @include transition(all 0.2s ease-in-out);
  }*/
}

/* ////////////////////////////////////////////////////// */
/* alerts */

.message-error {
  display: block;
  color: $color-red;
  font-size: $fs-12;
  margin: $spacing-sm 0;
}

/* ######################################################################################################## */
/* ######################################################################################################## */
/* ######################################################################################################## */
/* ######################################################################################################## */

@media screen and (min-width: $screen-md) {
  .fieldset-inline {
    @include flexbox();

    fieldset {
      + fieldset {
        margin: 0 0 0 $spacing-sm;
      }
    }
  }
}
