app-modal {
  visibility: hidden;

  .modal {
    .dialog {
      padding: $dialog-padding;
      background: $dialog-background;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 900;
      box-shadow: (0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%));
      @include border-radius(4px);
      @include opacity(0);
      @include scale(.8);
      @include transition (all 0.1s ease-in-out);

      .dialog-actions {
        top: 35px;
        right: 22px;
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 800;
      overflow: auto;
      background: transparentize(black, 0.68);
      @include opacity(0);
      @include transition(opacity .4s cubic-bezier(.25,.8,.25,1));
    }
  }
}

/* //////////////////////////////////////////////////////////////////// */

.modal-open {
  app-modal {
    visibility: visible;

    .modal {
      .dialog {
        @include scale(1);
        @include opacity(1);
      }

      .overlay {
        @include opacity(1);
      }
    }
  }
}
