.permissoes {
  header {
    margin-bottom: $spacing-md;

    fa-icon {
      margin-right: $spacing-sm;
    }
  }

  .flags {
    header {
      margin-bottom: $spacing-sm;
    }

    ul {
      @include flexbox();
      @include flex-align-items(center);
      @include flex-wrap(wrap);
    }

    li {
      margin: 0 5px 10px 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .mat-checkbox {
        width: 100%;
        position: relative;

        .mat-checkbox-label {
          width: 90px;
          height: 20px;
          @extend .box;
          @include flexbox();
          @include flex-direction(column);
          @include flex-align-items(center);
          @include flex-justify-content(center);

          fa-icon {
            font-size: $fs-30;
            @include transition(all 0.2s ease-in-out);
          }

          span {
            margin-top: $spacing-sm;
            @include transition(all 0.2s ease-in-out);
          }

          &:hover {

            fa-icon,
            span {
              @include opacity(0.7);
              @include transition(all 0.2s ease-in-out);
            }
          }
        }

        .mat-checkbox-inner-container {
          width: 25px;
          height: 25px;
          margin: 0 !important;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }

        .mat-checkbox-frame {
          display: none;
        }

        &.mat-checkbox-checked {
          .mat-checkbox-background {
            background: $color-green;
          }
        }
      }
    }
  }

  /* /////////////////////////////////////// */

  .group-definition {
    @include flexbox();
    @include flex-align-items(center);

    >.items {
      width: calc(50% - #{$spacing-md / 2});
    }

    /* /// */

    .intersection {
      margin: 0 $spacing-md;

      fa-icon {
        font-size: $fs-30;
      }
    }
  }

  /* /// */

  .table {
    &.scrollbar {
      height: 215px;
    }

    .table-header,
    .table-content {
      li {
        width: calc(100% - 10% - 26px - #{$spacing-md * 2});

        &:nth-child(1) {
          width: 100%;
        }

        .mat-checkbox {
          margin: calc(9px) 0 0 calc(10px);
        }
      }

    }

    .mat-checkbox {
      margin: calc(9px) 0 0 calc(10px);
    }
  }

  table {
    width: 100%;

    .mat-column-key {
      width: 20%;
    }

    .mat-column-value {
      width: 80%;
    }

    .mat-row {
      cursor: pointer;
    }

    .mat-row:hover {
      background-color: #ebebeb;
    }

    .mat-row.selected {
      background-color: #eaeaea;
    }
  }

}