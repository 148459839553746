.dialog {
  &.fechamento-caixa-valores-dinheiro {
    width: 800px;

    .data-content {
      &.table {
        .scrollbar {
          height: 180px;
        }

        .table-header,
        .table-content {
          li {
            width: calc(100% - #{$table-spacing * 7});;

          }
        }
      }
    }
  }
}
