.toolbar {
  height: $header-height;
  color: white;
  padding: $spacing-sm $main-padding;
  @include themify('background', 'toolbar-color');
  position: relative;
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(space-between);

  &.smaller {
    height: $header-height-smaller;
  }

  /*&:before {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right,
      $color-one   12.50%,
      $color-two   12.50%,
      $color-two   25%,
      $color-three 25%,
      $color-three 37.5%,
      $color-four  37.5%,
      $color-four  50%,
      $color-five  50%,
      $color-five  62.5%,
      $color-six   62.5%,
      $color-six   75%,
      $color-seven 75%,
      $color-seven 87.5%,
      $color-eight 87.5%,
      $color-eight 100%
    );
  }*/

  .group {
    @include flexbox();
    @include flex-align-items(center);

    + .group {
      margin: 0;
    }
  }

  .menu-toggle {
    + .logo {
      margin-left: $spacing-sm;
    }
  }

  .logo {
    margin: 0 $spacing-sm 0 0;
    cursor: pointer;
    @include flexbox;
    @include flex-align-items(center);

    img {
      height: 26px;
    }
  }

  .mat-icon-button,
  .user-button {
    color: white;
    background: transparent;
  }

  .mat-icon-button {
    padding: 0 5px;

    &.open {
      @include opacity(.5);
    }
  }

  fa-icon {
    font-size: $fs-20;
  }


}

/* ////////////////////////////////////////////////////////////////////////// */

.toolbar-container {
  height: 100%;
  overflow-x: hidden;
}

/* ////////////////////////////////////////////////////////////////////////// */

.user-actions {
  @include flexbox();
  @include flex-align-items(center);

  > li {
    position: relative;
    @include flexbox();
    @include flex-align-items(center);

    &:first-child {
      margin-right: calc(#{$main-padding / 2});
      @include flex-justify-content(center);

      button {
        width: calc(#{$sidenav-fixed-size-w} - #{$main-padding * 2});
        padding: 0;
      }
    }
  }

  button {
    padding: 5px 10px;
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
      + .tooltip {
        @include opacity(1);
        @include transition (all 0.2s ease-in-out);
        visibility: visible;
      }

      i, fa-icon {
        @include themify('color', 'brand-primary');
        @include themify('fill', 'brand-primary');
        @include transition (all 0.2s ease-in-out);
      }
    }

    i, fa-icon {
      font-size: $fs-14;
      color: white;
      @include transition (all 0.2s ease-in-out);
    }
  }

  .dropdown {
    min-width: 300px;
  }

  .tooltip {
    &.top {
      top: 40px;
    }
  }
}

/* ////////////////////////////////////////////////////////////////////////// */

.menu-dropdown {
  position: relative;
  display: flex;

  .dropdown {
    width: 100%;
    min-width: 350px;
    background: white;
    position: absolute;
    top: 15px;
    left: calc(#{- $sidenav-fixed-size-w} + #{$home-padding * 2});
    z-index: -1;
    visibility: hidden;
    @include box-shadow(1px 1px 4px transparentize(black, .8));
    @include themify('color', 'color-text-primary');
    @include transform(translateY(-110%));
    transition: transform .4s cubic-bezier(.25, .8, .25, 1), visibility .4s cubic-bezier(.25, .8, .25, 1);

    .content {
      ul {
        li {
          font-size: $fs-14;
          padding: 15px;
          position: relative;
          cursor: pointer;
          @include flexbox();
          @include flex-align-items(center);

          &:last-child {
            border: none;
          }

          &:hover {
            background: $color-gray-lt;
          }
        }
      }
    }

    &.open {
      @include transform(translateY(0));
      visibility: visible;
    }

    i, fa-icon {
      width: 20px;
      font-size: $icon-size-md;
      margin-right: 15px;
      @include flexbox();
      @include flex-align-items(center);

      img {
        width: 100%;
        max-width: 20px;
        max-height: 20px;
      }
    }

    .icon {
      font-size: $icon-size-md;
      margin-right: 10px;
    }
  }
}

/* ////////////////////////////////////////////////////////////////////////// */

.time {
  font-size: $fs-12;

  fa-icon {
    font-size: $fs-12;
  }
}

/* ////////////////////////////////////////////////////////////////////////// */

.system-info {
  font-size: $fs-12;
  @include flexbox();
  @include flex-align-items(center);

  ul, li {
    @include flexbox();
    @include flex-align-items(center);
  }

  li {
    padding: 0 10px;
    text-align: center;
    border-left: $border-toolbar;
    position: relative;

    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      padding: 2px 0 4px 10px;
    }

    .tooltip {
      @include flex-justify-content(center);
    }
  }

  fa-icon {
    font-size: $fs-12;
    margin-right: 5px;
  }

  /* /// */

  .network-status {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: white;
    cursor: help;
    @include border-radius(100rem);

    &.online {
      background: $color-green;
    }

    &.offline {
      background: $color-red-lt;
    }

    + .tooltip {
      right: -10px;
    }
  }
}
