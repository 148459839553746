.budget-contract {
  &.contract-new {
    .contract-type {
      header {
        margin-bottom: $spacing-sm;

        h1 {
          margin: 0;
        }
      }

      .actions {
        @include flexbox();
        @include flex-align-items(center);
        @include flex-wrap(wrap);

        li {
          margin: 0 $spacing-sm 0 0;

          &:nth-child(1) {
            color: $color-one;
            fa-icon {
              background-color: $color-one;
            }
          }
          &:nth-child(2) {
            color: $color-two;
            fa-icon {
              background-color: $color-two;
            }
          }
          &:nth-child(3) {
            color: $color-three;
            fa-icon {
              background-color: $color-three;
            }
          }
          &:nth-child(4) {
            color: $color-four;
            fa-icon {
              background-color: $color-four;
            }
          }
          &:nth-child(5) {
            color: $color-five;
            fa-icon {
              background-color: $color-five;
            }
          }
          &:nth-child(6) {
            color: $color-six;
            fa-icon {
              background-color: $color-six;
            }
          }
          &:nth-child(7) {
            color: $color-seven;
            fa-icon {
              background-color: $color-seven;
            }
          }
          &:nth-child(8) {
            color: $color-eight;
            fa-icon {
              background-color: $color-eight;
            }
          }

          .mat-radio-button {
            width: 100%;
            position: relative;

            .mat-radio-label {
              @extend .box;
              padding: 0;
              @include border-radius(100rem);
              @include flexbox();
              @include flex-align-items(center);
              @include flex-justify-content(center);
              @include transition(all 0.2s ease-in-out);

              &:hover {
                @include opacity(0.7);
                @include transition(all 0.2s ease-in-out);
              }

              .mat-radio-container {
                display: none;
              }

              fa-icon {
                width: 27px;
                font-size: $fs-22;
                text-align: center;
                color: white;
                padding: 10px;
                @include border-radius(100rem);
                @include box-shadow(1px 1px 4px transparentize(black, 0.8));
                @include transition(all 0.2s ease-in-out);

                + span {
                  margin: 0 $spacing-sm;
                }
              }

              .mat-radio-label-content {
                font-size: $fs-14;
                font-weight: 600;
                padding: 0 15px 0 0;
                @include flexbox();
                @include flex-align-items(center);
              }
            }

            /* /// */

            &.mat-radio-checked {
              .mat-radio-label {
                color: white;
                background: $color-eight;

                fa-icon {
                  background: $color-eight;
                }
              }
            }

            /* /// */

            &.mat-radio-disabled {
              @include opacity(0.7);

              .mat-radio-label {
                font-weight: 400;
                color: $disabled-text;
                background: $disabled-background;
                @include opacity(1);

                fa-icon {
                  color: $disabled-text;
                  background: $disabled-background;
                  @include box-shadow(1px 1px 4px transparentize(black, 0.9));
                }
              }
            }
          }
        }
      }
    }

    /* //////////////////////////////////////////////////////// */

    .contract-options {
      .products {
        height: 350px;

        .table-header,
        .table-content {
          li {
            &:nth-child(1) {
              width: 30px;
              text-align: center;
            }
            &:nth-child(2) {
              width: 15%;
            }
            &:nth-child(3) {
              width: calc(80% - 60px);
            }
            &:nth-child(4) {
              width: 15%;
            }
            &:nth-child(5) {
              width: 30px;
              text-align: center;
            }           
          }
        }

        .table-content {
          li {
            .fm-group {
              padding: 0;

              .mat-form-field-wrapper {
                background: white;
              }
            }
          }
        }
      }
    }

    /* //////////////////////////////////////////////////////// */

    .contract-details {
      textarea {
        height: 55px;
      }
    }

    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-md) {
      .contract-details {
        .fieldset-inline {
          fieldset {
            width: 70%;

            + fieldset {
              width: calc(30% - #{$spacing-sm});

              textarea {
                height: 151px;
              }
            }
          }
        }
      }

      /* ////////////////// */

      .contract-options {
        .products {
          height: 350px;

          .table-header,
          .table-content {
            li {
              &:nth-child(1) {
                width: 30px;
              }
              &:nth-child(2) {
                width: 10%;
              }
              &:nth-child(3) {
                width: calc(80% - 60px);
              }
              &:nth-child(4) {
                width: 10%;
              }
              &:nth-child(5) {
                width: 30px;
                text-align: center;
              }              
            }
          }
        }
      }
    }
  }
}
