.table-filter {
  font-size: $fs-12;
  padding-bottom: $main-padding;
  margin-bottom: $main-padding;
  border-bottom: $border-divider;
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(space-between);

  /* ////////////////////////////////////////////// */

  ul {
    width: 100%;
    @include flexbox();
    @include flex-align-items(center);

    li {
      + li {
        margin-left: $spacing-sm;
      }
    }
  }

  /* ////////////////////////////////////////////// */

  label,
  .mat-button,
  .mat-raised-button {
    padding: 6px 12px;
  }

  fa-icon {
    margin-right: $spacing-sm;
  }

  .buttons {
    margin: 0;
  }

  /* ////////////////////////////////////////////// */

  .filter-search,
  .filter-select,
  .filter-text,
  .mat-checkbox,
  .mat-button,
  .mat-raised-button {
    font-size: $fs-12 !important;
    font-weight: 400;
    line-height: normal;
    border: $border-divider;
    cursor: pointer;
    @include border-radius(4px);
    @include transition(all 0.2s ease-in-out);
    @include flexbox();
    @include flex-align-items(center);

    &:hover {
      background-color: $color-gray-xlt;
      @include transition(all 0.2s ease-in-out);
    }

    &:disabled {
      &:hover {
        background: inherit;
      }
    }

    button {
      width: auto;
      height: auto;
      color: $color;
      line-height: normal;
    }
  }

  /* ////////////////////////////////////////////// */

  .filter-select {
    .mat-form-field-wrapper {
      min-width: 150px;
      padding: 0;
      border: none;
    }

    .mat-select {
      padding: 0;
      margin: 0;
      @include transition(all 0.2s ease-in-out);

      &:hover {
        background-color: $color-gray-xlt;
        @include transition(all 0.2s ease-in-out);
      }
    }

    .mat-select-trigger {
      height: auto;
      padding: 7px 8px;
    }

    .mat-select-value {
      font-size: $fs-12;
    }
  }

  /* ////////////////////////////////////////////// */

  /* datepicker range */
  .filter-datepicker-range {
    max-width: 185px;

    .mat-form-field-wrapper {
      padding: 6px 8px 5px 8px;
      border: $border-divider;
      background: transparent;
      @include transition(all 0.2s ease-in-out);

      &:hover {
        background-color: $color-gray-xlt;
        @include transition(all 0.2s ease-in-out);
      }
    }

    .mat-form-field-label-wrapper {
      height: 20px;
      font-size: $fs-12;
      top: -4px;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float {
        .mat-form-field-label {
          display: none;
        }
      }
    }

    .mat-datepicker-toggle {
      color: $color;
      font-size: $fs-16;

      .mat-icon-button {
        padding: 4px 8px;
        margin: -4px -8px;

        svg {
          margin-top: -2px;
        }
      }
    }
  }

  /* ////////////////////////////////////////////// */

  /* datepicker */
  .filter-datepicker {
    @extend .filter-datepicker-range;

    .mat-form-field-label-wrapper {
      top: -1px;
    }
  }

  /* ////////////////////////////////////////////// */

  .filter-search {
    width: 300px;
    padding: 0 12px;

    fa-icon {
      margin: 0 0 0 5px;
    }

    input {
      width: calc(100% - 17px);
      font-size: $fs-12;
      line-height: normal;
      padding: 7px 0;
      border: none;
      background: transparent;
    }
  }

  /* ////////////////////////////////////////////// */

  .filter-text {
    height: 30px;
    padding: 0 15px;
    cursor: default;
    @include flexbox();
    @include flex-align-items(center);
  }
}
