.file-uploader {
  .file-uploader-wrapper {
    max-width: none;
    height: calc(100vh - 320px);
  }

  file-drop {
    height: 100%;
    padding: 0;
  }

  .file-drop-wrapper {
    height: 100%;
    padding: 0 !important;

    #dropZone {
      max-width: none;
      height: 100%;
      padding: $spacing-md;
      margin: auto;
      border: 2px dashed $color-gray-lt;
      background: white;
      @include border-radius(4px);
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(center);

      svg {
        fill: $color-blue;
      }

      .content-top-text,
      .content-center-text {
        color: $color;
        font-weight: 500;
      }

      .file-browse-button {
        height: $button-height;
        color: $color;
        white-space: normal;
        border: $border-divider;
        cursor: pointer;
        background: white;
        @include box-shadow(none);
        @include border-radius(4px);
        @include transition(all 0.2s ease-in-out);

        &:hover {
          background: transparentize($color-gray-xlt, .2);
          @include transition (all 0.2s ease-in-out);
        }
      }
    }
  }

  .dropzoneTemplate {
    @include flexbox();
    @include flex-direction(column);
    @include flex-align-items(center);
  }

  file-preview-item {
    max-width: none;
  }

  /* /////////////////////////////////////////////////////////// */

  &.smaller {
    .file-drop-wrapper,
    .files-preview-wrapper {
      zoom: .8;
    }
  }
}

/* ================================================================================================================== */

.cropperJsBox {
  min-width: 600px;
  min-height: 400px;
  @extend .box;
  @include border-radius(4px);
  @include flex-align-items(flex-start!important);
  background: $dialog-background;
  position: relative;

  &:before {
    content: "Edição de imagem";
    @include font-size($h1-fs);
    font-weight: 900;
    margin-bottom: 30px;
  }

  .cropper-container {
    margin: 0 auto;
  }

  .cropper-actions {
    width: 100%;
    margin-top: $spacing-md;
    @include flexbox();
    @include flex-direction(row-reverse);
    @include flex-align-items(center);
    @include flex-justify-content(center);

    .cropSubmit {
      background: $color-green !important;
    }
  }
}
