.chart-donut {
  @include flex-justify-content(flex-start);

  .chart {
    width: calc(50vh - 240px);
    height: calc(50vh - 240px);
    min-width: auto;
    min-height: auto;
  }

  .legend {
    font-size: $fs-14;
    @include columns(auto);

    li {
      padding: 0;
    }

    p {
      line-height: normal;
      position: relative;
      padding-left: 15px;

      em {
        width: 10px;
        height: 4px;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }


}

