app-loading {
  width: calc(#{$loading-size} + #{$loading-border-size * 2});
  height: calc(#{$loading-size} + #{$loading-border-size * 2});
  margin: calc(#{- $loading-size} / 2 - #{$loading-border-size }) 0 0 calc(#{- $loading-size} / 2 - #{$loading-border-size});
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  @include box-shadow(1px 1px 4px transparentize(black, .4));
  @include border-radius(100px);

  .loading {
    @include flexbox();

    &:after {
      content: "";
      width: $loading-size;
      height: $loading-size;
      border-width: $loading-border-size;
      border-style: solid;
      @include themify('border-color', 'brand-primary');
      border-top-color: white!important;
      @include border-radius(100rem);
      @include loading-transition(1s linear infinite);
    }
  }
}
