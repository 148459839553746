.dialog {
  &.rio-log-visualizar {
    width: 1000px;

    /* //////////////////////////////////////////////////////// */

    .table {
      .scrollbar.itens {
        height: 300px;

        .table-header,
        .table-content {
          li {
            width: calc(70% / 6);

            &:nth-child(2) {
              width: calc(30% - #{$table-spacing * 6});
            }
          }
        }
      }

      .scrollbar.lotes {
        height: 300px;

        .table-header,
        .table-content {
          li {
            width: calc(80% / 8);

            &:nth-child(2) {
              width: calc(20% - #{$table-spacing * 8});
            }
          }
        }
      }

    }
  }

  @media print {
    .table {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: 10000;

      .table-filter {
        display: none;
      }

      .table-content {
        border-bottom: 2px #000 solid;
      }
    }
  }
}
