.dialog {
  padding: 0;
  position: relative;

  > header {
    height: $dialog-header;
    margin-bottom: $dialog-padding;
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content-end();

    .actions {
      @include flexbox();
      @include flex-align-items(center);

      li {
        position: relative;
        cursor: pointer;

        &:last-child {
          a {
            padding-right: 0;
          }

          button {
            margin-right: 0;
          }
        }

        button {
          width: 40px;
          height: 24px;
          font-size: $fs-18;
          color: $color-gray;
          background: none;
          border: none;
          margin: 0 5px;
          @include transition (all 0.1s ease-in-out);

          &:hover,
          &.active {
            @include themify('color', 'brand-primary');
            @include transition (all 0.1s ease-in-out);
          }
        }
      }

      a {
        padding: 0 10px;
      }
    }

    .tooltip {
      &.top {
        &.right {
          right: 10px;
          top: 37px;
        }
      }
    }
  }

  .box {
    padding: $dialog-box-padding;
  }

  .label {
    font-size: $fs-14;
    margin-bottom: $spacing-sm;
  }

  .group-inline {
    @include flexbox();

    .group {
      + .group {
        margin-left: $main-padding;
      }
    }
  }

  /* /////////////////////////////////////////////////////////////////// */
  /* table */

  mat-row {
    min-height: 30px !important;
  }

  mat-header-cell,
  mat-cell,
  mat-footer-cell {
    font-size: $fs-12;
    flex: auto;

    &:first-child,
    &:last-child {
      padding: 0;
    }
  }

  .dialog-close,
  .dialog-actions {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    @include flexbox();
    @include flex-align-items(center);

    li {
      position: relative;

      &:last-child {
        button {
          padding-right: 0;
        }
      }
    }

    button {
      width: 40px;
      font-size: $fs-18;
      color: $color-gray;
      background: none;
      border: none;
      padding: 0 10px;
    }

    .tooltip {
      &.top {
        &.right {
          right: 5px;
        }
      }
    }

    .mat-button-wrapper {
      @include flexbox();
      @include flex-align-items(center);

      .mat-icon {
        margin-right: 5px;
      }
    }
  }
}

/* /////////////////////////////////////////////////////////////////// */
/* skeleton */

.dialog-full-screen {
  width: calc(100% - #{$sidenav-fixed-size-w});
  height: calc(100% - 30px)!important;
  max-width: none!important;
  max-height: none!important;
  margin: auto 0 0 auto;

  .dialog {
    width: 100%;
    height: 100%;
  }

  .mat-dialog-container {
    @include border-radius(0!important);
  }
}

.mat-dialog-container {
  padding: 0!important;
  overflow: hidden!important;
  background: none!important;
  display: -webkit-flex!important;
  display:  -webkit-box!important;
  display:  -ms-flexbox!important;
  display:     -moz-box!important;
  display:         flex!important;
  @include border-radius(2px!important);

  @include flex-align-items(center);
  @include flex-justify-content(center);

  > .ng-star-inserted {
    width: calc(100% - #{$dialog-padding * 2});
    height: calc(100% - #{$dialog-padding * 2});
    padding: $dialog-padding;
    background: $dialog-background;
    @include flexbox();

    @media screen and (max-width: 600px) {
      @include flex-align-items(flex-start);
      @include flex-justify-content(flex-start);
    }

    @media screen and (min-width: 600px) {
      @include flex-align-items(center);
      @include flex-justify-content(center);
    }
  }
}

.cdk-overlay-pane {
  max-width: 95vw!important;
}
