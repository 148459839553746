.budget-contract {
  &.contract-query {

    .mat-tab-content {
      height: calc(100vh - 180px);

      .scrollbar {
        height: calc(100vh - 365px);

      }
    }

    .table {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {width: 10%}
          &:nth-child(2) {width: 45%;}
          &:nth-child(3) {width: 10%; text-align: center;}
          &:nth-child(4) {width: 15%; text-align: center;}
          &:nth-child(5) {width: 10%; text-align: center;}
          &:nth-child(6) {width: 10%; text-align: center;}
        }
      }

      /* /// */

      .more {
        ul {
          li {
            width: calc(25% / 2);

            &:nth-child(1) {
              width: 10%;
            }

            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(6) {
              width: calc(65% / 3 - #{$table-spacing * 3} - 2px);
            }
          }
        }
      }
    }

  }
}
