/* define brand styles */

$brand-themes: (
  'd1000': (
    'brand-primary'                  : #71bf4b,
    'brand-secondary'                : #089ef4,
    'color-text-primary'             : #555555,
    'color-text-secondary'           : #ffffff,
    'highlight-color'                : #089ef4,
    'toolbar-color'                  : #231f20,
    'sidenav-fixed-color'            : #ffffff,
    'sidenav-fixed-background'       : linear-gradient(135deg, #96d049 50%, #8dc63f 50%),
    'sidenav-fixed-background-hover' : linear-gradient(135deg, #96d049 50%, #8dc63f 50%),
    'button-text-color'              : #ffffff,
    'button-color'                   : linear-gradient(170deg, #93ce44 50%, #8dc63f 50%),
    'button-color-hover'             : linear-gradient(170deg, #93ce44 50%, #8dc63f 50%),
    'button-color-border'            : #8dc63f,
    'tooltip-arrow'                  : transparent #2d2d2b transparent transparent,
    'tooltip-background'             : linear-gradient(165deg, #2d2d2b 50%, #1d1d1b 50%),
    'box-title-color'                : #555555,
    'box-button-color'               : #555555,
    'table-color-hover'              : #ffffff,
    'table-background-active'        : linear-gradient(180deg, #96d049 50%, #8dc63f 50%),
    'card-amount-color'              : #ffffff,
    'card-amount-background'         : linear-gradient(165deg, #11a7fa 50%, #089ef4 50%),
    'card-total-color'               : #ffffff,
    'card-total-background'          : linear-gradient(165deg, #252525 50%, #1d1d1b 50%),
    'form-suffix-prefix'             : #555555,
    'form-toggle'                    : #006cb7,
    'form-toggle-background'         : #3b8ed0,
    'background-brand'               : none
  ),
  'profarma': (
    'brand-primary'                  : #bbc324,
    'brand-secondary'                : #089ef4,
    'color-text-primary'             : #1d1d1b,
    'color-text-secondary'           : #ffffff,
    'highlight-color'                : #089ef4,
    'toolbar-color'                  : linear-gradient(180deg, #2d2d2b 50%, #1d1d1b 50%),
    'sidenav-fixed-color'            : #ffffff,
    'sidenav-fixed-background'       : linear-gradient(135deg, #96d049 50%, #8dc63f 50%),
    'sidenav-fixed-background-hover' : linear-gradient(135deg, #96d049 50%, #8dc63f 50%),
    'button-text-color'              : #ffffff,
    'button-color'                   : linear-gradient(170deg, #96d049 50%, #8dc63f 50%),
    'button-color-hover'             : linear-gradient(170deg, #96d049 50%, #8dc63f 50%),
    'button-color-border'            : #8dc63f,
    'tooltip-arrow'                  : transparent #2d2d2b transparent transparent,
    'tooltip-background'             : linear-gradient(165deg, #2d2d2b 50%, #1d1d1b 50%),
    'box-title-color'                : #555555,
    'box-button-color'               : #bbc324,
    'table-color-hover'              : #ffffff,
    'table-background-active'        : linear-gradient(180deg, #96d049 50%, #8dc63f 50%),
    'card-amount-color'              : #ffffff,
    'card-amount-background'         : linear-gradient(165deg, #11a7fa 50%, #089ef4 50%),
    'card-total-color'               : #ffffff,
    'card-total-background'          : linear-gradient(165deg, #252525 50%, #1d1d1b 50%),
    'form-suffix-prefix'             : #555555,
    'form-toggle'                    : #006cb7,
    'form-toggle-background'         : #3b8ed0,
    'background-brand'               : none
  ),
  'farmalife': (
    'brand-primary'                  : #f06423,
    'brand-secondary'                : #96005a,
    'color-text-primary'             : #1d1d1b,
    'color-text-secondary'           : #ffffff,
    'highlight-color'                : #96005a,
    'toolbar-color'                  : linear-gradient(180deg, #2d2d2b 50%, #1d1d1b 50%),
    'sidenav-fixed-color'            : #ffffff,
    'sidenav-fixed-background'       : linear-gradient(135deg, #f56e2f 50%, #f06423 50%),
    'sidenav-fixed-background-hover' : linear-gradient(135deg, #f56e2f 50%, #f06423 50%),
    'button-text-color'              : #ffffff,
    'button-color'                   : linear-gradient(170deg, #f56e2f 50%, #f06423 50%),
    'button-color-hover'             : linear-gradient(170deg, #f56e2f 50%, #f06423 50%),
    'button-color-border'            : #f06423,
    'tooltip-arrow'                  : transparent #2d2d2b transparent transparent,
    'tooltip-background'             : linear-gradient(165deg, #2d2d2b 50%, #1d1d1b 50%),
    'box-title-color'                : #96005a,
    'box-button-color'               : #f06423,
    'table-color-hover'              : #ffffff,
    'table-background-active'        : linear-gradient(180deg, #f56e2f 50%, #f06423 50%),
    'card-amount-color'              : #ffffff,
    'card-amount-background'         : linear-gradient(165deg, #9e0e64 50%, #96005a 50%),
    'card-total-color'               : #ffffff,
    'card-total-background'          : linear-gradient(165deg, #252525 50%, #1d1d1b 50%),
    'form-suffix-prefix'             : #f06423,
    'form-toggle'                    : #f06423,
    'form-toggle-background'         : #f56e2f,
    'background-brand'               : image-default('farmalife.png')
  ),
  'drogasmil': (
    'brand-primary'                  : #ebb200,
    'brand-secondary'                : #9f0737,
    'color-text-primary'             : #1d1d1b,
    'color-text-secondary'           : #ffffff,
    'highlight-color'                : #9f0737,
    'toolbar-color'                  : linear-gradient(180deg, #2d2d2b 50%, #1d1d1b 50%),
    'sidenav-fixed-color'            : #ffffff,
    'sidenav-fixed-background'       : linear-gradient(135deg, #f3bb09 50%, #ebb200 50%),
    'sidenav-fixed-background-hover' : linear-gradient(135deg, #f3bb09 50%, #ebb200 50%),
    'button-text-color'              : #ffffff,
    'button-color'                   : linear-gradient(170deg, #f3bb09 50%, #ebb200 50%),
    'button-color-hover'             : linear-gradient(170deg, #f3bb09 50%, #ebb200 50%),
    'button-color-border'            : #ebb200,
    'tooltip-arrow'                  : transparent #2d2d2b transparent transparent,
    'tooltip-background'             : linear-gradient(165deg, #2d2d2b 50%, #1d1d1b 50%),
    'box-title-color'                : #9f0737,
    'box-button-color'               : #ebb200,
    'table-color-hover'              : #ffffff,
    'table-background-active'        : linear-gradient(180deg, #f3bb09 50%, #ebb200 50%),
    'card-amount-color'              : #ffffff,
    'card-amount-background'         : linear-gradient(165deg, #aa0c3d 50%, #9f0737 50%),
    'card-total-color'               : #ffffff,
    'card-total-background'          : linear-gradient(165deg, #252525 50%, #1d1d1b 50%),
    'form-suffix-prefix'             : #ebb200,
    'form-toggle'                    : #ebb200,
    'form-toggle-background'         : #f3bb09,
    'background-brand'               : image-default('drogasmil.png')
  ),
  'tamoio': (
    'brand-primary'                  : #006cb7,
    'brand-secondary'                : #71bf43,
    'color-text-primary'             : #1d1d1b,
    'color-text-secondary'           : #ffffff,
    'highlight-color'                : #71bf43,
    'toolbar-color'                  : linear-gradient(180deg, #2d2d2b 50%, #1d1d1b 50%),
    'sidenav-fixed-color'            : #ffffff,
    'sidenav-fixed-background'       : linear-gradient(135deg, #0778c6 50%, #006cb7 50%),
    'sidenav-fixed-background-hover' : linear-gradient(135deg, #0778c6 50%, #006cb7 50%),
    'button-text-color'              : #ffffff,
    'button-color'                   : linear-gradient(170deg, #0778c6 50%, #006cb7 50%),
    'button-color-hover'             : linear-gradient(170deg, #0778c6 50%, #006cb7 50%),
    'button-color-border'            : #006cb7,
    'tooltip-arrow'                  : transparent #2d2d2b transparent transparent,
    'tooltip-background'             : linear-gradient(165deg, #2d2d2b 50%, #1d1d1b 50%),
    'box-title-color'                : #71bf43,
    'box-button-color'               : #006cb7,
    'table-color-hover'              : #ffffff,
    'table-background-active'        : linear-gradient(180deg, #0778c6 50%, #006cb7 50%),
    'card-amount-color'              : #ffffff,
    'card-amount-background'         : linear-gradient(165deg, #79c74b 50%, #71bf43 50%),
    'card-total-color'               : #ffffff,
    'card-total-background'          : linear-gradient(165deg, #252525 50%, #1d1d1b 50%),
    'form-suffix-prefix'             : #006cb7,
    'form-toggle'                    : #006cb7,
    'form-toggle-background'         : #0778c6,
    'background-brand'               : image-default('tamoio.png')
  ),
  'rosario': (
    'brand-primary'                  : #006cb7,
    'brand-secondary'                : #ed1c24,
    'color-text-primary'             : #1d1d1b,
    'color-text-secondary'           : #ffffff,
    'highlight-color'                : #ed1c24,
    'toolbar-color'                  : linear-gradient(180deg, #2d2d2b 50%, #1d1d1b 50%),
    'sidenav-fixed-color'            : #ffffff,
    'sidenav-fixed-background'       : linear-gradient(135deg, #0778c6 50%, #006cb7 50%),
    'sidenav-fixed-background-hover' : linear-gradient(135deg, #0778c6 50%, #006cb7 50%),
    'button-text-color'              : #ffffff,
    'button-color'                   : linear-gradient(170deg, #0778c6 50%, #006cb7 50%),
    'button-color-hover'             : linear-gradient(170deg, #0778c6 50%, #006cb7 50%),
    'button-color-border'            : #006cb7,
    'tooltip-arrow'                  : transparent #2d2d2b transparent transparent,
    'tooltip-background'             : linear-gradient(165deg, #2d2d2b 50%, #1d1d1b 50%),
    'box-title-color'                : #ed1c24,
    'box-button-color'               : #006cb7,
    'table-color-hover'              : #ffffff,
    'table-background-active'        : linear-gradient(180deg, #0778c6 50%, #006cb7 50%),
    'card-amount-color'              : #ffffff,
    'card-amount-background'         : linear-gradient(165deg, #f6242c 50%, #ed1c24 50%),
    'card-total-color'               : #ffffff,
    'card-total-background'          : linear-gradient(165deg, #252525 50%, #1d1d1b 50%),
    'form-suffix-prefix'             : #006cb7,
    'form-toggle'                    : #006cb7,
    'form-toggle-background'         : #0778c6,
    'background-brand'               : image-default('rosario.png')
  ),
) !default;

/* //////////////////////////////////// */
/* config */

@mixin themify-global($property, $key, $themes: $brand-themes) {
  @each $theme, $variables in $themes {
    .theme-#{$theme} & {
      #{$property}: map-get($variables, $key);
    }
  }
}

@mixin themify-direct($property, $key, $themes: $brand-themes) {
  @each $theme, $variables in $themes {
    .theme-#{$theme} > & {
      #{$property}: map-get($variables, $key);
    }
  }
}

@mixin themify-element($property, $key, $themes: $brand-themes) {
  @each $theme, $variables in $themes {
    &.theme-#{$theme} {
      #{$property}: map-get($variables, $key);
    }
  }
}

@mixin themify($property, $value) {
  @include themify-global($property, $value);
  @include themify-direct($property, $value);
  @include themify-element($property, $value);
}

/* //////////////////////////////////// */
/* usage */

/* set class "theme-" in body tag */
/* <body class="theme-BRAND"> */

/* use include in your class */
/*.class {
  @include themify('background-color', 'brand-primary');
}*/
