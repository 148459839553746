.dialog {
  &.rio-log-pesquisa-nota-fiscal {
    width: 1000px;

    /* //////////////////////////////////////////////////////// */

    .table {
      .scrollbar {
        height: 300px;
      }

      .table-header,
      .table-content {
        li {
          width: calc(62% / 6);

          &:nth-child(7) {
            width: calc(30% - #{$table-spacing * 8});
          }

          &:nth-child(8) {
            width: calc(8% - #{$table-spacing * 8});
          }

          .preview {
            color: $color-blue;
            font-weight: bolder;
            cursor: pointer;
            font-size: large;
          }
        }
      }

    }
  }
}
