* {
  margin: 0;
  padding: 0;
  outline: none;
}

html, body {
  min-height: 100%;
  height: 100%;
  font-family: $font;
  @include font-size($body-fs);
  line-height: normal;
  color: $color!important;
  background: $background;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior-y: contain; /* disable pull to refresh, keeps glow effects */
}

.base {
  @include flexbox();
}

.mat-drawer-container {
  color: $color;
}

div {
  box-sizing: border-box;
}

ul,li {
  list-style: none;
}

h1 {
  @include font-size($h1-fs);
  margin: 0 0 $spacing-sm 0;
}

h2 {
  @include font-size($h2-fs);
  margin: 0 0 $spacing-sm 0;
}

p {
  line-height: $paragraph-line-height;
  position: relative;

  + p {
    margin-top: $paragraph-spacing;
  }
}

small {
  font-size: $fs-10;
}

i {
  svg {
    width: 60px;
  }
}

a {
  @include transition (all 0.2s ease-in-out);

  &:hover {
    @include transition (all 0.2s ease-in-out);
  }
}

object {
  pointer-events: none; /* fix click-router action */
}

.text-ellipsis {
  width: 100%;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  display: block;
}

.container {
  padding: 0 $container-x;
}

section {
  padding: $container-y $container-x;

  header {
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content-end();

    h1 {
      margin: 0;
    }

    .buttons {
      margin: 0;
    }
  }

  + section {
    padding-top: 0;
  }
}

.item-name {
  width: 100%;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  display: block;

  i {
    margin-left: auto;
  }
}

.highlight {
  @include themify('color', 'brand-secondary');
}

.flip-h {
  @include transform(scaleX(-1));
}

.disabled-area {
  filter: grayscale(1);
  @include opacity(.3)
}

.subtitle {
  font-size: $fs-12;
  margin-top: 10px;

  span {
    padding-left: 20px;
    position: relative;

    &:before {
      content: '';
      width: 15px;
      height: 10px;
      top: 3px;
      left: 0;
      background-color: gray;
      position: absolute;
    }

    &.yellow {
      &:before {
        background-color: $color-yellow;
      }
    }

    &.red {
      &:before {
        background-color: $color-red;
      }
    }

    &.green {
      &:before {
        background-color: $color-green-hv;
      }
    }
  }
}

.box {
  padding: $main-padding;
  background: white;
  position: relative;
  @include border-radius(2px);
  @include box-shadow(1px 1px 4px transparentize(black, .8));

  > header {
    font-size: $fs-14;
    font-weight: 600;
    @include themify('color', 'box-title-color');
    padding: 0 $main-padding $main-padding $main-padding;
    margin: 0 calc(#{- $main-padding}) $main-padding calc(#{- $main-padding});
    border-bottom: $border-header;
    z-index: 2;
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content-end();

    h1, h2 {
      font-weight: 600;
      margin-bottom: 0;
    }

    h1 {
      font-size: $fs-16;
    }

    h2 {
      font-size: $fs-14;
    }

    .header-group {
      @include flexbox();
      @include flex-align-items(center);
    }

    .header-info {
      @include flexbox();
      @include flex-align-items(center);

      li {
        + li {
          margin-left: $spacing-md;
        }
      }

      + .header-actions {
        margin-left: $spacing-lg;
      }

      fa-icon {
        + span {
          margin-left: 5px;
        }
      }
    }

    .header-actions {
      margin: -6px 0;
      @include flexbox();
      @include flex-align-items(center);

      li {
        position: relative;

        &:last-child {
          a, button {
            padding-right: 0;
          }
        }
      }

      button, a {
        font-size: $fs-16;
        @include themify('color', 'box-button-color');
        padding: 4px 10px;
        border: none;
        background: none;
        cursor: pointer;
        @include flexbox();
        @include flex-align-items(center);
        @include flex-justify-content(center);

        &:disabled,
        &.disabled {
          color: $color-gray;
          cursor: default;
          @include opacity(.3);

          fa-icon {
            color: $color-gray;
          }
        }

        &.enabled {
          @include themify('color', 'box-button-color');
        }
      }
    }
  }

  .buttons {
    margin: calc(#{$main-padding}) 0 0 0;
  }
}

.box-inline {
  @include flexbox();
  @include flex-justify-content(space-between);
}

.group {
  + .group,
  + .group-inline,
  + .fm-inline {
    margin-top: $spacing-sm;
  }
}

.group-inline {
  @include flexbox();
  @include flex-justify-content(space-between);

  + .group-inline,
  + .group,
  + .fm-inline {
    margin-top: $spacing-sm;
  }

  .group {
    + .group {
      margin: 0 0 0 $spacing-sm;
    }
  }
}

.align-center {
  width: 100%;
  height: 100%;
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(center);
}

/* snackbar */
.mat-simple-snackbar {
  color: white;
}

.mat-simple-snackbar-action {
  .mat-button-wrapper {
    color: $color-green;
  }
}

.mat-menu-item {
  fa-icon {
    margin-right: 10px;
  }
}

.mat-ripple-element {
  display: none;
  /*transition-duration: 0ms !important;*/
} /* disable click effect */

.size-5  {width:  5% !important;}
.size-10 {width: 10% !important;}
.size-15 {width: 15% !important;}
.size-20 {width: 20% !important;}
.size-25 {width: 25% !important;}
.size-30 {width: 30% !important;}
.size-35 {width: 35% !important;}
.size-40 {width: 40% !important;}
.size-45 {width: 45% !important;}
.size-50 {width: 50% !important;}
.size-55 {width: 55% !important;}
.size-60 {width: 60% !important;}
.size-65 {width: 65% !important;}
.size-70 {width: 70% !important;}
.size-75 {width: 75% !important;}
.size-80 {width: 80% !important;}
.size-85 {width: 85% !important;}
.size-90 {width: 90% !important;}
.size-95 {width: 95% !important;}
.size-100 {width: 100% !important;}


/* ######################################################################################################## */
/* ######################################################################################################## */
/* ######################################################################################################## */
/* ######################################################################################################## */

@media screen and (min-width: $screen-md) {
  .section-inline {
    @include flexbox();

    section {
      width: 50%;
    }
  }
}

/* ============================================================================================================================================ */

.mat-drawer-content {
  overflow-x: hidden;
}

code.hljs {
  white-space: pre;
  padding: 16px;
}

@keyframes pageLoad {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.page-load-animation {
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: pageLoad ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  background: #FFF;
  z-index: 9999;
  pointer-events: none;
}

@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}

$height__buy-now-toolbar: 60px;

.buy-now-toolbar {
  height: $height__buy-now-toolbar;
  position: relative;
  z-index: 999;

  & + .layout-container {
    height: calc(100% - #{$height__buy-now-toolbar}) !important;
  }

  .rainbow {
    height: 100%;
    width: 100%;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1800% 1800%;
    animation: rainbow 18s ease infinite;
    padding: 0 24px;

    .name {
      color: white;
      font-weight: 500;
      text-shadow: 0 0 20px rgba(150, 150, 150, 1);
    }

    .action {
      margin-left: 24px;

      .icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        margin-right: 6px;
      }
    }

    .close {
      color: white;
      text-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }
  }
}
