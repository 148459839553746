.dropdown-item {
  display: block;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  transition: color .1s;

  .dropdown-indicator {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-right: 6px;
    transform: rotate(-90deg);
    transition: transform .25s cubic-bezier(.25,.8,.25,1);

    &.rotate {
      @include transform(rotate(0));
    }
  }

  .letter-icon {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.sub-menu {
  overflow: hidden;
  transition: height .4s cubic-bezier(.35, 0, .25, 1);
}
