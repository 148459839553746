.vtex {
  &.monitoring {
    padding: $main-padding;

   

    /* /////////////////////////////////////////////////////////////////////////////////////////////////// */

    .monitoring-options {
      .multiple-selector {

        .ms-options {
          width: 200px;
        }

        .ms-result {
          width: calc(100% - 180px - #{$spacing-md});

          .list-buttons {
            li {
              width: calc(100% / 6 - #{$spacing-md});
            }
          }
        }

        .scrollbar {
          height: 270px;
        }
      }
    }

    /* /////////////////////////////////////////////////////////////////////////////////////////////////// */

    .statistics {
      margin-top: $spacing-md;
      @include flexbox();

      section {
        width: calc(50% - #{$spacing-md / 2});
        height: calc(100vh - 260px);

        + section {
          margin-left: $spacing-md;
        }
      }

      /* /////////////// */

      .order-integration {
        .chart-gauge {
          @include flex-direction(column);

          .chart {
            width: calc(65vh - 180px);
            height: calc(65vh - 180px);
          }

          .legend {
            width: 100%;
            margin: 0;
            @include columns(auto);
            @include flexbox();

            li {
              width: calc(100% / 2);

              &:nth-child(3) {
                width: 25%;
              }
            }

            .content {
              small {
                font-size: $fs-14;
              }
            }

            fa-icon {
              width: 35px;
              height: 35px;
              font-size: $fs-20;
            }
          }

          .total {
            font-size: $fs-40;
            margin-top: $spacing-xlg;

            p {
              + p {
                margin-top: $spacing-sm;

                small {
                  font-size: $fs-20;
                }
              }
            }
          }
        }
      }

      /* /////////////// */

      .order-steps {
        .chart-donut {
          height: calc(100% - #{$spacing-md *3});

          .chart {
            width: calc(65vh - 180px);
            height: calc(65vh - 180px);

            .total {
              font-size: $fs-40;

              p {
                + p {
                  margin-top: $spacing-sm;

                  small {
                    font-size: $fs-20;
                  }
                }
              }
            }
          }

          .legend {
            margin-left: $spacing-xlg;
            @include columns(auto);
            @include flexbox();
            @include flex-direction(column);

            li {
              + li {
                margin-top: $spacing-sm;
              }

              &:nth-child(4) {
                fa-icon {
                  font-size: $fs-18;

                  svg {
                    margin-left: 8px;
                  }
                }
              }
            }

            .content {
              margin-left: $spacing-sm;

              small {
                font-size: $fs-14;
              }
            }

            fa-icon {
              width: 35px;
              height: 35px;
              font-size: $fs-20;
            }

            p {
              + p {
                margin-top: calc(#{$spacing-sm} / 2);
              }
            }
          }
        }
      }
    }

    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-xlg) {

      .statistics {
        .order-integration {
          .chart-gauge {
            .legend {
              .content {
                p {
                  + p {
                    font-size: $fs-22;
                  }
                }

                small {
                  font-size: $fs-16;
                }
              }

              fa-icon {
                width: 40px;
                height: 40px;
                font-size: $fs-22;
              }
            }
          }
        }

        /* /////////////////// */

        .order-steps {
          .chart-donut {
            .legend {
              .content {
                p {
                  + p {
                    font-size: $fs-22;
                  }
                }

                small {
                  font-size: $fs-16;
                }
              }

              fa-icon {
                width: 40px;
                height: 40px;
                font-size: $fs-22;
              }
            }
          }
        }
      }

    }

  }
}
