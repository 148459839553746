.dialog-register-branch {
  width: 1500px;

  .mat-form-field-infix {
    display: inline-flex;
    align-items: baseline;
    width: 100%;
  }

  .mat-icon-button {
    width: auto;
    height: auto;
    padding-right: 8px;
    margin: -10px;
  }

  .farmacia-popular {
    margin-top: $main-padding;
  }
}


