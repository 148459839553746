@import '~@angular/material/theming';

/* =========================================================================================================== */
/* media queries */

$media-gt-md : 'screen and (max-width: 1279px)';
$media-md    : 'screen and (min-width: 960px) and (max-width: 1279px)';
$media-lt-sm : 'screen and (max-width: 959px)';

$screen-sm   : 768px; /* (tablets, 768px and up) */
$screen-md   : 1024px; /* (desktops, 1024px and up) */
$screen-lg   : 1200px; /* (laptops, 1200px and up) */
$screen-xlg  : 1400px; /* (large desktops, 1400px and up) */
$screen-xxlg : 1900px; /* (extra large desktops, 1900px and up) */

/* =========================================================================================================== */
/* spacing */

$gutter      : 1rem !default;
$spacing-sm  : 10px !default;
$spacing-md  : 20px !default;
$spacing-lg  : 40px !default;
$spacing-xlg : 60px !default;

$main-padding : 20px !default;
$home-padding : 10px !default;
$container-x  : 20px !default;
$container-y  : 20px !default;

$sidenav-padding: 0 20px !default;
$sidenav-item-padding: 20px !default;

$box-margin :  5px !default;

$paragraph-spacing     : 20px !default;
$paragraph-line-height : 26px !default;

$table-spacing :  5px !default;

$table-header  : 34px !default;
$table-spacing : 10px !default;
$table-padding :  5px !default;

$dialog-header       : 30px !default;
$dialog-padding      : 20px !default;
$dialog-box-padding  : 20px !default;
$dialog-box-margin   : 20px !default;

/* =========================================================================================================== */
/* styles */

$header-height         : 60px !default;
$header-height-smaller : 30px !default;
$breadcrumb-height     : 40px !default;

$sidenav-fixed-size-w        : 6vw !default;
$sidenav-fixed-size-h        : calc(100vh - #{$header-height-smaller}) !default;
$sidenav-fixed-size-bt       : calc(100vh / 6) !default;
$sidenav-fixed-size-bt-hover : calc(100vh / 6 - 5px) !default;

$border-divider     : 1px solid #e1e1e1 !default;
$border-table       : 1px solid #eaeaea !default;
$border-toolbar     : 1px solid #555555 !default;
$border-header      : 1px solid #eaeaea !default;

$scrollbar-size    : 6px !default;
$scrollbar-spacing : 10px !default;

$accordion-icon : 12px !default;

$icon-size    : 28px !default;
$icon-size-sm : 16px !default;
$icon-size-md : 22px !default;

$button-height    : 40px !default;
$button-height-sm : 30px !default;

$preloader-size : 100px !default;

$loading-size        : 20px !default;
$loading-border-size :  2px !default;

$form-field-padding : 8px !default;

/* =========================================================================================================== */
/* theme */

// Include the common and theme styles for Angular Material.
$theme-primary: mat-palette($mat-blue-grey, 700, 500, 900);
$theme-accent:  mat-palette($mat-blue);
$theme-warn:    mat-palette($mat-red);
$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);

$width__sidenav: 270px;
$width__sidenav-collapsed-collapsed: 70px;
$width__sidenav-collapsed: 270px;

$padding-left__sidenav-item: 36px;
$padding-right__sidenav-item: 24px;

$layout-beta__background__sidenav: white;

/*
  Sidenav: Layout Beta
 */

$layout-beta__color__sidenav-item: #555;
$layout-beta__color__sidenav-heading: rgba(0, 0, 0, 0.3);
$layout-beta__background__sidenav-item__icon: rgba(0, 0, 0, 0.05);

$layout-beta__background-color__sidenav-item: $layout-beta__background__sidenav;
$layout-beta__background-color__sidenav-item__hover: darken($layout-beta__background-color__sidenav-item, 2);
$layout-beta__background-color__sidenav-item__active: $layout-beta__background-color__sidenav-item__hover;

$layout-beta__background-color__sidenav-item__level1: darken($layout-beta__background-color__sidenav-item, 2.5);
$layout-beta__background-color__sidenav-item__level1__hover: darken($layout-beta__background-color__sidenav-item__level1, 1.5);
$layout-beta__background-color__sidenav-item__level1__active: $layout-beta__background-color__sidenav-item__level1__hover;

$layout-beta__background-color__sidenav-item__level2: darken($layout-beta__background-color__sidenav-item__level1, 2.5);
$layout-beta__background-color__sidenav-item__level2__hover: darken($layout-beta__background-color__sidenav-item__level2, 1.5);
$layout-beta__background-color__sidenav-item__level2__active: $layout-beta__background-color__sidenav-item__level2__hover;

$layout-beta__background-color__sidenav-item__level3: darken($layout-beta__background-color__sidenav-item__level2, 2.5);
$layout-beta__background-color__sidenav-item__level3__hover: darken($layout-beta__background-color__sidenav-item__level3, 1.5);
$layout-beta__background-color__sidenav-item__level3__active: $layout-beta__background-color__sidenav-item__level3__hover;

$layout-beta__background-color__sidenav-item__level4: darken($layout-beta__background-color__sidenav-item__level3, 2.5);
$layout-beta__background-color__sidenav-item__level4__hover: darken($layout-beta__background-color__sidenav-item__level4, 1.5);
$layout-beta__background-color__sidenav-item__level4__active: $layout-beta__background-color__sidenav-item__level4__hover;

$layout-beta__background-color__sidenav-item__level5: darken($layout-beta__background-color__sidenav-item__level4, 2.5);
$layout-beta__background-color__sidenav-item__level5__hover: darken($layout-beta__background-color__sidenav-item__level5, 1.5);
$layout-beta__background-color__sidenav-item__level5__active: $layout-beta__background-color__sidenav-item__level5__hover;

$layout-beta__background-color__sidenav-item__level6: darken($layout-beta__background-color__sidenav-item__level5, 2.5);
$layout-beta__background-color__sidenav-item__level6__hover: darken($layout-beta__background-color__sidenav-item__level6, 1.5);
$layout-beta__background-color__sidenav-item__level6__active: $layout-beta__background-color__sidenav-item__level6__hover;

/*
  Sidenav-Item: Padding
 */

$padding__sidenav-item__level1: $padding-left__sidenav-item + 10px;
$padding__sidenav-item__level2: $padding-left__sidenav-item + 20px;
$padding__sidenav-item__level3: $padding-left__sidenav-item + 30px;
$padding__sidenav-item__level4: $padding-left__sidenav-item + 40px;
$padding__sidenav-item__level5: $padding-left__sidenav-item + 50px;
$padding__sidenav-item__level6: $padding-left__sidenav-item + 50px;
