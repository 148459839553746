.responsive-full-screen-mat-dialog {

    @media screen and (max-width: 600px) {
        width: 100% !important;
        height: 100% !important;
        max-width: 100vw !important;
        max-height: 100vh !important;

        #app-cadastro-ordem-servico {
            align-items: flex-start !important;
        }
    }

}