.chart-donut {
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(center);

  .chart {
    min-width: 100px;
    min-height: 100px;
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content(center);

    canvas {
      position: relative;
      z-index: 1;
    }

    .total {
      font-size: $fs-26;
      font-weight: 500;
      text-align: center;
      position: absolute;

      p {
        line-height: 18px;

        + p {
          margin-top: 5px;
        }

        small {
          font-size: $fs-16;
          font-weight: 400;
        }
      }
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////// */


  .legend {
    margin-left: $spacing-md;
    @include columns(3);

    li {
      padding: 5px;
      @include flexbox();
      @include flex-align-items(center);
    }

    fa-icon {
      @extend .box;
      width: 30px;
      height: 30px;
      font-size: $fs-16;
      padding: 0;
      margin-right: $spacing-sm;
      @include border-radius(100rem);
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(center);
    }

    .content {
      small {
        font-size: $fs-12;
      }

      p {
        line-height: normal;

        + p {
          font-size: $fs-18;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////// */

  .table-list {
    .table-content {
      cursor: pointer;
    }
  }
}
