.nps {

  .actions {
    padding-bottom: 0;
  }

  .mat-accordion {
    padding: $main-padding;
  }

  .dashboard {
    padding: $main-padding;
    @include flexbox();
    @include flex-wrap(wrap);
  }

  /* ////////////////////////////////////////////////////////////////// */

  .history-rating {
    width: calc(75% - #{$main-padding * 3});

    .chart-bar {
      height: calc(100vh - 300px);

      canvas {
        max-height: calc(100vh - 300px);
      }
    }
  }

  /* ////////////////////////////////////////////////////////////////// */

  .chart-rating {
    width: 25%;
    margin-left: $main-padding;
  }

  /* ////////////////////////////////////////////////////////////////// */

  .score {
    .chart-gauge {
      @include flex-justify-content(center);
    }

    .chart {
      width: calc(50vh - 118px);
      height: calc(50vh - 228px);

      .total {
        margin-top: $spacing-lg;
      }
    }
  }

  /* ////////////////////////////////////////////////////////////////// */

  .total-rating {
    margin-top: $main-padding;

    .chart-donut {
      .chart {
        width: calc(50vh - 192px);
        height: calc(50vh - 192px);
      }

      .legend {
        width: 30%;
        margin: 0 0 0 $spacing-md;
        @include flexbox();
        @include flex-justify-content(space-around);
        @include flex-direction(column);

        li {
          padding: 0;

          + li {
            margin-top: 6px;
          }
        }

        fa-icon {
          width: auto;
          height: auto;
          font-size: $fs-22;
          margin: 0 5px 0 0;
          @include box-shadow(none);
        }
      }
    }
  }


  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */

  @media screen and (min-width: $screen-xlg) {

    .total-rating {
      .chart-donut {
        @include flex-direction(column);

        .chart {
          width: calc(50vh - 235px);
          height: calc(50vh - 235px);
        }

        .legend {
          width: 90%;
          margin: $spacing-md 0 0 0;
          @include flex-direction(row);
          @include flex-align-items(center);

          li {
            @include flex-direction(column);

            + li {
              margin: 0 0 0 $spacing-md;
            }
          }

          fa-icon {
            font-size: $fs-36;
            margin: 0;
          }

          p {
            padding: 5px 0 0 0;
          }
        }
      }

    }

    /* ////////////////////////////////////////////////////////////////// */

    .score {
      .chart {
        width: calc(50vh - 153px);
        height: calc(50vh - 263px);
      }

      .chart-donut {
        .legend {
          fa-icon {
            font-size: $fs-60;
          }
        }
      }
    }

  }

}
