.dialog-filial-consulta {
  width: 400px;

  .query-response {
    > p {
      font-size: $fs-14;
      color: $color-red;
      margin-top: $spacing-sm;
    }

    .buttons {
      @include flex-justify-content(space-between);
    }
  }
}
