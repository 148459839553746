.scrollbar,
textarea {
  padding-right: $scrollbar-spacing;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color-track;
    @include border-radius(100rem);
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-color-background;
    @include border-radius(100rem);
  }

  /* Works on Firefox */
  scrollbar-color: $scrollbar-color-track $scrollbar-color-background; /* thumb - track */
  scrollbar-width: thin;

  /* ////////////////////////////////////////////////////////////////////////// */

  .scrollbar-container {
    height: 100%;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
  }

  .scrollbar-track {
    cursor: pointer;
    @include opacity(1);
    @include border-radius(4px);

    .scrollbar-thumb {
      @include transition (all 0.2s ease-in-out);
    }

    &:hover {
      .scrollbar-thumb {
        background: $color-gray;
        @include transition (all 0.2s ease-in-out);
      }
    }
  }
}
