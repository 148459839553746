.layout {
  height: 100%;
  @include flexbox();
  @include flex-direction(column);
}

/* //////////////////////////////////////// */

.layout-container {
  height: 100%;

  .mat-drawer-container {
    height: calc(100% - #{$header-height});
  }
}

/* //////////////////////////////////////// */

.options-toggle {
  width: 48px;
  height: 48px;
  color: white;
  background: rgb(40, 50, 60);
  position: absolute;
  top: 200px;
  right: 0;
  z-index: 99999;
  cursor: pointer;
  @include opacity(.7);
}

/* //////////////////////////////////////// */

.content-container {
  height: 100%;
  overflow: auto;
  position: relative;
}
