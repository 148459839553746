.horizontal-navigation {
  background: white;
  position: relative;

  .more-button-container {
    height: 56px;
  }

  .more-dropdown {
    transition: transform .25s cubic-bezier(.25,.8,.25,1), visibility 0s linear .25s;
    @include transform(translate3d(0, -100%, 0));

    &.open {
      transition: transform .25s cubic-bezier(.25,.8,.25,1) .1s, visibility 0s linear .1s;
      @include transform(translate3d(0, 0, 0));
    }
  }
}
