app-check-staging {
  font-size: $fs-14;
  position: absolute;
  top: 0;
  left: 50%;
  margin: -4px 0 0 -125px;
  z-index: 1;

  span {
    width: 250px;
    height: 40px;
    color: white;
    background: $color-red;
    @include border-radius(4px);
    @include flexbox();
    @include flex-align-items(center);
    @include flex-justify-content(center);
  }
}
