.cross-sell {
  app-cross-sell-novo {
    position: inherit !important;
  }

  &.cross-sell-query {

    .table {
      .scrollbar {
        height: calc(100vh - 373px);
      }

      .table-header,
      .table-content {
        li {
          width: calc(100% - 10% - #{$spacing-md * 2});

          &:nth-child(1) {
            width: 10%;
          }
        }

        .item-name {
          max-width: 90%;
        }
      }
    }

    .edited {
      display: flex;

      img {
        width: 80px;
        margin-right: 10px;
      }
    }

  }
}
