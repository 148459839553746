.preloader {
  width: 100%;
  height: 100%;
  @include flexbox();
  @include flex-align-items(center);
  @include flex-justify-content(center);
  background: white;
  position: fixed;
  z-index: 9999;

  &.loader {
    background: transparentize(white, .2);
    display: none;
  }

  .first-group {
    width: $preloader-size;
    height: $preloader-size;
    position: absolute;
    perspective: 100rem;
    @include transform(rotateZ(45deg));

    &:before,
    &:after {
      content: '';
      width: $preloader-size;
      height: $preloader-size;
      position: absolute;
      @include border-radius(100rem);
      @include animation(1s preloader linear infinite);
    }

    &:before {
      @include transform(rotateX(60deg));
      color: $color-brand-one;
    }

    &:after {
      color: $color-brand-two;
      @include transform(rotateY(60deg));
      animation-delay: .5s;
    }
  }

  /* ////////// */

  .last-group {
    @extend .first-group;
    @include rotate(180deg);

    &:before {
      color: $color-brand-three;
    }

    &:after {
      color: $color-brand-four;
    }
  }
}
