.register {
  .box {
    + .box {
      margin-top: $main-padding;
    }
  }

  /* /////////////////////////////////////////// */

  &.flags {
    .table {
      .table-header,
      .table-content {
        > li {

          &:nth-child(2) {
            width: calc(30% - #{$table-padding * 2 * 5});
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 15%;
          }

          &:nth-child(3) {
            @include flex-justify-content(center);
          }

          &:nth-child(4),
          &:nth-child(5) {
            @include flex-justify-content(flex-start);

            &:hover {
              a {
                @include opacity(1);
                @include transition (all 0.2s ease-in-out);
              }
            }

            a {
              padding: 10px 20px 10px 10px;
              @include opacity(0);
              @include transition (all 0.2s ease-in-out);
            }
          }

          &:nth-child(6) {
            @include flex-justify-content(flex-end);
          }
        }
      }
    }
  }

  /* /////////////////////////////////////////// */

  .scrollbar {
    height: calc(100vh - 311px);
  }

  .table {
    .table-header,
    .table-content {
      > li {
        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2) {
          width: calc(60% - #{$table-padding * 2 * 4});
        }

        &:nth-child(3),
        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(3) {
          @include flex-justify-content(center);
        }

        &:nth-child(4) {
          @include flex-justify-content(flex-end);
        }

        .buttons {
          fa-icon {
            font-size: $fs-14;
          }
        }
      }
    }
  }

  /* ///////////////////////////////////////////////////////////////////////// */

  &.register-flag {
    width: 600px;
    margin: -240px 0 0 -300px;
  }

  /* ///////////////////////////////////////////////////////////////////////// */

  .log {
    .scrollbar {
      height: 200px;
    }

    .table {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 20%;
          }

          &:nth-child(2) {
            width: calc(60% - #{$table-padding * 2 * 4});
          }

          &:nth-child(3),
          &:nth-child(4) {
            width: 10%;
            @include flex-justify-content(center);
          }
        }
      }
    }
  }

}
