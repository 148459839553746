.breadcrumbs {
  height: $breadcrumb-height;
  padding: 0 $main-padding;
  background: $breadcrumb-bg;
  @include flexbox();
  @include flex-align-items(center);

  ul {
    @include flexbox();
    @include flex-align-items(center);

    li {
      @include flexbox();
      @include flex-align-items(center);
    }
  }

  fa-icon {
    color: $color;
  }

  .link {
    text-decoration: none;
    transition: opacity .2s ease-in-out;
    font-size: $fs-14;
    @include themify('color', 'color-text-primary');
  }

  .chevron {
    font-size: $fs-20;
    line-height: 20px;
    height: 20px;
    width: 20px;
    margin-bottom: -2px;
  }
}
