.painel-nfce {
  &.consulta {
    display: block;

    .options {
      @extend .chart-donut;

      .legend {
        width: 100%;
        margin: 0 0 $main-padding 0;
        @include columns(auto);
        @include flexbox();
        @include flex-justify-content(space-between);

        li {
          width: calc(100% / 6);
          border: $border-divider;
          padding: calc(#{$spacing-sm / 2}) $spacing-sm;
          background: linear-gradient(170deg, transparentize(white, .3) 50%, transparent 50%);
          cursor: pointer;
          @include border-radius(4px);
          @include box-shadow(1px 1px 3px transparentize(black, .88));
          @include transition (all 0.2s ease-in-out);

          &:hover {
            background-position: 0 51px!important;
            @include transition (all 0.2s ease-in-out);
          }

          + li {
            margin-left: $spacing-md;
          }

          &:nth-child(1) {fa-icon, p:last-child {color: $color-blue;}}
          &:nth-child(2) {fa-icon, p:last-child {color: $color-green;}}
          &:nth-child(3) {fa-icon, p:last-child {color: $color-yellow;}}
          &:nth-child(4) {fa-icon, p:last-child {color: $color-red;}}
          &:nth-child(5) {fa-icon, p:last-child {color: $color-purple;}}
          &:nth-child(6) {fa-icon, p:last-child {color: $color-gray-md;}}

          &.active {
            background: transparent;
            cursor: default;
            @include box-shadow(none);
          }
        }
      }
    }

    /* ///////////////////////////// */

    .table {
      .scrollbar {
        height: calc(100vh - 364px);
      }

      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 3%;
            @include flex-justify-content(center);
          }

          &:nth-child(2) {
            width: 6%;
          }

          &:nth-child(3) {width: calc(5% - 10px);}


          &:nth-child(4),
          &:nth-child(5) {width: calc(10% - 10px);}

          &:nth-child(6),
          &:nth-child(7) {
            width: 15%;
            @include flex-justify-content(space-between);
          }

          &:nth-child(8) {
            width: calc(20% - 10px);
          }
        }

        .item-name {
          max-width: 90%;
        }
      }

      .table-content {
        &:first-child {
          .file-check {
            color: $color-green;
          }

          .file-exclamation {
            color: $color-orange-lt;
          }

          .file-edit {
            color: $color-red;
          }
        }
      }

    }
  }
}
