.version-panel {
  padding: calc(#{$main-padding / 2});
  @include flexbox();
  @include flex-wrap(wrap);

  > li {
    width: calc(100% / 6);
  }

  .scrollbar {
    height: 84px;
  }

  .box {
    height: calc(100vh / 2 - 100px);
    padding: calc(#{$main-padding / 2});
    margin: calc(#{$main-padding / 2});

    > header {
      padding: 0 20px 10px 20px;
      margin: 0 calc(#{- $main-padding}) calc(#{$main-padding / 2}) calc(#{- $main-padding});

      h1 {
        font-size: 12px;
      }
    }
  }

  /* ///////////////////////////////////////////////////////// */

  .chart-donut {
    @include flex-direction(column);

    .chart {
      width: calc(50vh - 220px);
      height: calc(50vh - 220px);
      min-width: auto;
      min-height: auto;
      margin-bottom: $spacing-sm;

      .total {
        font-size: $fs-16;
      }

      + .legend {
        margin-left: $main-padding;
      }
    }

    /* /////////// */

    .legend {
      min-width: 120px;

      > p {
        font-size: $fs-14;
        font-weight: 700;
        margin-bottom: $spacing-sm;
      }

      ul {
        li {
          font-size: $fs-14;
          @include flexbox();
          @include flex-align-items(center);

          + li {
            margin-top: $spacing-sm;
          }

          fa-icon {
            margin-right: $spacing-sm;
          }
        }
      }
    }
  }

  /* ///////////////////////////////////////////////////////// */

  .table {
    font-size: $fs-12;

    .table-header,
    .table-content {
      li {
        height: auto;
        text-align: left;
        padding: 3px 5px;

        &:nth-child(1) {
          width: 5%;
        }

        &:nth-child(2) {
          width: calc(65% - #{$table-padding});
        }

        &:nth-child(3) {
          width: 30%;
        }
      }
    }

    .table-header {
      padding-bottom: 8px;

      li {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */

  @media screen and (min-width: $screen-xlg) {
    > li {
      width: calc(100% / 4);
    }

    .box {
      height: calc(100vh / 3 - 100px);
      padding: $main-padding;

      > header {
        h1 {
          font-size: $fs-16;
        }
      }
    }

    .scrollbar {
      height: 108px;
    }

    /* ////////////// */

    .chart-donut {
      height: calc(100% - 40px);
      @include flex-direction(row);

      .chart {
        width: 160px;
        margin: 0 $spacing-md 0 0;

        .total {
          font-size: $fs-20;
        }
      }
    }

    /* ////////////// */

    .table {
      font-size: $fs-14;

      .table-header,
      .table-content {
        li {
          height: 20px;
        }
      }
    }
  }
}
