.search {
  @include flexbox();
  @include flex-align-items(center);

  button {
    z-index: 2;
  }

  .search-bar {
    width: 0;
    visibility: hidden;
    @include flexbox();
    @include opacity(0);
    @include transition(all .4s cubic-bezier(.35,0,.25,1));

    &.search-open {
      width: 400px;
      margin-left: -40px;
      visibility: visible;
      @include opacity(1);
    }

    .mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        border-color: white;
        background: transparent;
      }
    }

    .mat-form-field-appearance-legacy {
      .mat-input-element {
        color: white;
        padding-left: 40px;
      }
    }
  }

  .search-wrapper {
    position: relative;
    width: 100%;

    &.focus {
      .search-icon {
        color: rgba(0, 0, 0, 0.87);
      }

      input.search-input {
        background: white;
        color: rgba(0, 0, 0, 0.87);

        &::-webkit-input-placeholder {  color: rgba(0, 0, 0, 0.87);  }
        &:-moz-placeholder {  opacity: 1;  color: rgba(0, 0, 0, 0.87);  }
        &::-moz-placeholder {  opacity: 1;  color: rgba(0, 0, 0, 0.87);  }
        &:-ms-input-placeholder {  color: rgba(0, 0, 0, 0.87);  }
      }

      .search-dropdown {
        transform: translateY(0);
        visibility: visible;
      }
    }

    .search-dropdown {
      background: white;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      z-index: -1;
      transform: translateY(-110%);
      visibility: hidden;
      transition: transform .4s cubic-bezier(.25,.8,.25,1), visibility .4s cubic-bezier(.25,.8,.25,1);

      .content {
        padding: 16px;
        font-size: 14px;

        .heading {
          font-size: 13px;
          color: #888;
          margin-bottom: 2px;

          .icon {
            font-size: 20px;
            height: 20px;
            width: 20px;
            line-height: 20px;
            margin-right: 6px;
          }
        }

        .items {
          .item {
            position: relative;
            padding: 8px 0 6px 26px;
            margin: 2px 0;
            cursor: pointer;
            transition: background .1s;
            outline: none;

            &:hover {
              background: #EFEFEF;
            }
          }
        }
      }
    }
  }

}
