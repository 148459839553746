.painel-carga {
  &.estatisticas {
    height: calc(100vh - #{$breadcrumb-height} - #{$header-height});

    .navigation {
      padding-bottom: 0;
    }

    .statistics-panels {

      section {
        height: calc(45vh - #{$container-y *2} - #{$header-height});

        + section {
          height: calc(55vh - #{$container-y *2} - #{$header-height});
          padding-top: $spacing-md;
        }
      }

      .chart-bar {
        canvas {
          max-height: calc(50vh - 140px);
        }
      }

      .chart-donut {
        .chart {
          width: calc(50vh - 100px);
          height: calc(50vh - 160px);
        }
      }
    }

    /* /// */

    .group-inline {
      height: 100%;

      .group {
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include flex-justify-content(center);
      }
    }

    /* /// */

    .statistics-success {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: calc(85% - #{$table-padding *2} - #{$table-spacing});
          }
          &:nth-child(2) {
            width: 15%;
            text-align: center;
          }
        }
      }
    }

    .statistics-error {
      .table-header,
      .table-content {
        li {
          &:nth-child(1) {
            width: 15%;
          }
          &:nth-child(2) {
            width: calc(70% - #{$table-padding *3} - #{$table-spacing});
          }
          &:nth-child(3) {
            width: 15%;
            text-align: center;
          }
        }
      }
    }


    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */
    /* ######################################################################################################## */

    @media screen and (min-width: $screen-lg) {
      height: calc(100vh - #{$breadcrumb-height} - #{$header-height});

      .statistics-panels {
        @include flexbox();

        section {
          width: calc(50% - 20px);
          height: calc(100vh - #{$container-y *2} - #{$header-height} - 100px);

          + section {
            height: calc(100vh - #{$container-y *2} - #{$header-height} - 100px);
            padding-left: 0;
          }
        }
      }

      /* /// */

      .group-inline {
        @include flex-direction(column);

        .group {
          width: 100%;
          height: calc(50% - #{$spacing-sm});

          + .group {
            margin: $spacing-sm 0 0 0;
          }
        }
      }
    }

  }
}
