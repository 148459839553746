.export {

  .multiple-selector {
    .scrollbar {
      height: calc(100vh - 462px);
    }

    .list-buttons {
      li {
        .mat-radio-button,
        .mat-checkbox {
          .mat-radio-label,
          .mat-checkbox-label {
            width: 140px;
          }
        }
      }
    }

    /* ///////////////////// */

    .ms-result {
      .filter {
        .filter-search {
          width: 177px;
        }
      }

      .list-buttons {
        li {
          width: calc(100% - 20px);
          .mat-radio-button,
          .mat-checkbox {
            width: 100%;

            .mat-radio-label,
            .mat-checkbox-label {
              width: 100%;
              text-align: left;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  /* ////////////////////////////////////////////////////// */

  .log {
    .scrollbar {
      height: calc(100vh - 485px);
    }

    .table {
      .table-header,
      .table-content {
        li {
          width: 10%;

          &:nth-child(3) {
            width: calc(90% - #{$table-padding * 2 * 3});
          }
        }
      }
    }
  }



  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */
  /* ######################################################################################################## */

  @media screen and (min-width: $screen-md) {
    .multiple-selector {
      .ms-result {
        .filter {
          .filter-search {
            width: 300px;
          }
        }

        .list-buttons {
          li {
            width: calc(100% / 2 - 20px);
          }
        }
      }
    }
  }

}
