.dialog {
  &.fechamento-caixa-saldo-inicial {
    width: 700px;

    .insert-data {
      textarea {
        height: 55px;
      }

      .buttons {
        @include flex-justify-content(center);

        fa-icon {
          margin-right: 5px;
        }
      }
    }

    /* //////////////////////////////////// */

    .data-content {
      &.table {
        .scrollbar {
          height: 180px;
        }

        .table-header,
        .table-content {
          li {
            width: 15%;

            &:nth-child(4) {
              width: calc(55% - #{$table-spacing * 4});
            }
          }
        }
      }
    }

  }
}
