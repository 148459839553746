.branch-list {
  .table-header,
  .table-content {
    > li {
      width: 10%;

      &:nth-child(2) {
        width: calc(60% - #{$table-padding * 2 * 5});
      }

      &:last-child {
        @include flex-justify-content(flex-end);
      }
    }
  }
}
