.cross-sell {
  section {
    > header {
      margin-bottom: $spacing-md;

      fa-icon {
        margin-right: $spacing-sm;
      }
    }
  }
}
