.change-history {
  width: 600px;

  .table,
  .table.expansion {

    .table-header,
    .table-content {
      li {
        &:nth-child(1) {
          width: calc(60% - #{$table-padding * 2 * 3})
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 20%;
          text-align: center;
        }
      }
    }

    .scrollbar {
      height: 250px;
    }

    /* /// */

    .mat-expansion-panel {
      .mat-expansion-panel-content {
        .more {
          ul {
            display: block;

            li {
              width: 100%;
              padding: $table-spacing 0;

              strong {
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    /* /// */

    .edited {
      font-style: italic;
      text-decoration: line-through;

      &:before {content: " ( ";}
      &:after {content: " ) "}
    }
  }

}
