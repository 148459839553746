.parametrizacao {
  &.sla-pedidos {

    .order-steps {
      height: calc(100vh - 285px);
      margin-top: $main-padding;
      @include flexbox();
      @include flex-align-items(center);
      @include flex-justify-content(space-around);

      > li {
        @include flexbox();
        @include flex-align-items(center);
        @include flex-justify-content(center);

        fa-icon {
          width: 30px;
          height: 30px;
          font-size: $fs-30;
          text-align: center;
          line-height: 0;
          color: white;
          background: $color-gray;
          margin-right: $spacing-md;
          display: block;
          @extend .box;
          @include border-radius(100rem);
        }

        &:nth-child(1) {
          fa-icon {
            color: white;
            background: $color-brand-one;
          }
        }

        &:nth-child(2) {
          fa-icon {
            color: white;
            background: $color-brand-two;
          }
        }

        &:nth-child(3) {
          fa-icon {
            color: white;
            background: $color-brand-four;
          }
        }

        &:nth-child(4) {
          fa-icon {
            color: white;
            background: $color-brand-three;
          }
        }
      }

      .mat-form-field {
        max-width: 110px;
      }

      ul {
        li {
          + li {
            margin-top: $spacing-md;
          }
        }
      }


    }
  }

}
